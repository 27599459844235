import axios from "axios";

import {Organization} from "./interface/Organization";
import {Batch, BatchColumnsType} from "./interface/Batch";
import {Auth} from "../auth";
import {API_BASE_URL} from "./constants";

const loginSF = (to?: string | null) => {
    const url = new URL(to ?? "/", window.location.origin).toString();
    window.location.href = `${API_BASE_URL}/authHandler/login/sf?url=${url}`;
}

const loginEmail = async (email: string, password: string) => {
    const {data: {token}} = await axios.post(`${API_BASE_URL}/authHandler/login/email`, {
        email,
        password
    });

    return token;
}

export type SignupPayload = {
    orgName: string;
    businessNumber: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
}
const signup = async (payload: SignupPayload) => {
    const {data} = await axios.post(`${API_BASE_URL}/authHandler/signup`, payload);
    return data;
}

let orgInfo: Organization | undefined;
const fetchOrganization = async (): Promise<Organization> => {
    if (orgInfo == null) {
        const {data} = await axios.get<Organization>(`${API_BASE_URL}/account`);
        orgInfo = data;
    }
    return orgInfo;
}

const updateOrganization = async (payload: Partial<Organization>): Promise<Organization> => {
    const {data} = await axios.put<Organization>(`${API_BASE_URL}/account`, payload);
    orgInfo = data;

    return orgInfo;
}

const createBatch = async (payload: Partial<Batch>, file: File): Promise<Batch> => {
    const {
        data: {
            presignedPost: {
                fields,
                url
            },
            ...batchRecord
        }
    } = await axios.post(`${API_BASE_URL}/batch`, {
        ...payload,
        orig_file_name: file.name
    });

    const formData = new FormData();
    Object.entries<string>(fields).forEach(([name, value]) => {
        formData.append(name, value);
    });
    formData.append("file", file);
    await axios.post(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": ' ',
        }
    });

    return batchRecord;
}

async function fetchBatchMappings() {
    const {data} = await axios.get<BatchColumnsType>(`${API_BASE_URL}/account/batchMapping`);
    return data;
}

let batchColumnsPromise: Promise<BatchColumnsType> | undefined;

async function getBatchColumns(nocache?: boolean) {
    if (nocache || batchColumnsPromise == null) {
        batchColumnsPromise = fetchBatchMappings();
    }

    return batchColumnsPromise;
}

Auth.checkAuthenticated().then(async () => {
    // Prefill cache
    await getBatchColumns();
});

const syncBatch = async (id: string, values: any) => {
    await axios.post(`${API_BASE_URL}/batch/${id}/sync`, values);
}

const syncAsset = async (id: string, values: any) => {
    await axios.post(`${API_BASE_URL}/account/asset/${id}/sync`, values);
}

export type AccountOverallStats = {
    batches: number;
    rows: number;
    spend_included: number;
    spend_ignored: number;
    energy_usage_cost: number;
    gl_spend_energy: number;
}

const fetchOverallStats = async (): Promise<AccountOverallStats> => {
    const {data} = await axios.get(`${API_BASE_URL}/account/stats/overall`);
    return data;
};

export type BatchAggregatedStats = {
    scope1: number;
    scope2: number;
    scope3: number;
    group: string;
}

export type FetchStatsParams = {
    from?: string;
    to?: string;
    batchId?: string;
    groupId?: string;
    assetId?: string;
    groupBy?: "category" | "year";
}
const fetchStats = async (params: FetchStatsParams): Promise<BatchAggregatedStats[]> => {
    const {data} = await axios.get(`${API_BASE_URL}/account/stats`, {params});
    return data;
};

export const API = {
    loginSF,
    loginEmail,
    signup,
    fetchOrganization,
    updateOrganization,
    createBatch,
    getBatchColumns,
    syncBatch,
    syncAsset,
    fetchStats,
    fetchOverallStats,
};
