import {Modal} from "antd";
import {Col, Form, Row} from "@pankod/refine-antd";

import {CommonModalProps} from "./interfaces";
import {OtherFactorSet} from "./components/OtherFactorSet";
import {useModalSyncForm} from "./useModalSyncForm";

export function FuelSyncModal(props: CommonModalProps) {
    const {modalProps, formProps} = useModalSyncForm(props);

    return (
        <Modal {...modalProps}>
            <Form {...formProps}>
                <Row style={{marginTop: "1.5rem"}}>
                    <Col span={20} offset={2}>
                        <OtherFactorSet queryEnabled={props.open}/>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}
