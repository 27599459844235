import {normal} from "color-blend";
import {RGBA} from "color-blend/dist/types";

export function mixColors(backdrop: string, source: string): string {
    const backdropRGBA = parseColor(backdrop);
    const sourceRGBA = parseColor(source);
    const result = normal(backdropRGBA, sourceRGBA);
    return rgbaToString(result);
}

const HEX_REGEXP = /^#([0-9A-F]{3,8})$/i;
const RGBA_REGEXP = /^rgba?\((\d{1,3},\s*\d{1,3},\s*\d{1,3}(?:,\s*(?:\d\.)?\d{1,3})?)\)$/i;

function parseColor(col: string): RGBA {
    const hexParsed = HEX_REGEXP.exec(col);
    if (hexParsed != null) {
        const color = hexParsed[1]!;
        if (color.length === 5 || color.length === 7) {
            throw new Error(`HEX color cannot consist of ${color.length} characters`);
        }
        if (color.length < 5) {
            // color.length is 3 or 4
            return {
                r: parseHexComponent(color[0])!,
                g: parseHexComponent(color[1])!,
                b: parseHexComponent(color[2])!,
                a: parseHexComponent(color[3]) ?? 1
            }
        }
        if (color.length === 6) {
            return {
                r: parseHexComponent(color.substring(0, 2))!,
                g: parseHexComponent(color.substring(2, 4))!,
                b: parseHexComponent(color.substring(4, 6))!,
                a: 1
            }
        }
        else {
            // color.length === 8
            return {
                r: parseHexComponent(color.substring(0, 1))!,
                g: parseHexComponent(color.substring(2, 3))!,
                b: parseHexComponent(color.substring(4, 5))!,
                a: parseHexComponent(color.substring(6, 7))!,
            }
        }
    }

    const rgbaParsed = RGBA_REGEXP.exec(col);
    if (rgbaParsed != null) {
        const [r, g, b, a] = rgbaParsed[1]!.split(",").map((s) => s.trim());
        return {
            r: parseInt(r!),
            g: parseInt(g!),
            b: parseInt(b!),
            a: a == null ? 1 : parseFloat(a)
        }
    }
    else {
        throw new Error(`Could not parse color string "${col}"`);
    }
}

function parseHexComponent(component?: string) {
    if (component == null) {
        return undefined;
    }

    if (component.length > 2) {
        throw new Error("Hex color component shouldn't be longer than 2 characters");
    }
    if (component.length === 1) {
        component = component + component;
    }
    return parseInt(component, 16);
}

function rgbaToString({r, g, b, a}: RGBA): string {
    return `rgba(${[r, g, b, a].join(",")})`;
}
