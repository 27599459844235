import {ReactNode} from "react";

export type FormContainerProps = {
    children: ReactNode | ReactNode[];
}
export const FormContainer = ({children}: FormContainerProps) => {
    return (
        <div style={{maxWidth: "33rem", margin: "1rem auto 0"}}>
            {children}
        </div>
    );
}
