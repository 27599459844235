import {useCallback, useMemo} from "react";
import {G2, Pie} from "@ant-design/plots";
import {theme} from "@pankod/refine-antd";

const G = G2.getEngine("canvas");

export function ScopesChart({data}: {data: Array<any>}) {
    const {token} = theme.useToken();

    const percentValue = useMemo(() => {
        return data.reduce((res, {value}) => res + value, 0) / 100;
    }, [data]);

    const formatValue = useCallback((val: number) => {
        const percent = Math.round((val / percentValue) * 100) / 100;
        return `${Number(val ?? 0).toFixed(2)}(${percent}%)`;
    }, [percentValue]);

    const customItems = useCallback((originalItems: any) => {
        const originalItem = originalItems[0];
        originalItem.value = formatValue(originalItem.value);
        return originalItems;
    }, [formatValue]);

    const labelFormatter = useCallback((data: any, mappingData: any) => {
        const group = new G.Group({});
        group.addShape({
            type: "circle",
            attrs: {
                x: 0,
                y: 0,
                r: 5,
                fill: mappingData.color,
            },
        });
        group.addShape({
            type: "text",
            attrs: {
                x: 10,
                y: 8,
                text: data.scope,
                fill: token.colorText,
                fontWeight: 700,
            },
        });
        group.addShape({
            type: "text",
            attrs: {
                x: 0,
                y: 25,
                text: formatValue(data.value),
                fill: token.colorTextDescription,
            },
        });
        return group;
    }, [token, formatValue]);

    return (
        <Pie
            data={data}
            appendPadding={10}
            angleField="value"
            colorField="scope"
            radius={0.5}
            legend={false}
            label={{
                type: "spider",
                formatter: labelFormatter,
            }}
            tooltip={{customItems}}
        />
    );
}
