import {CloudUploadOutlined} from "@ant-design/icons";
import {Button} from "@pankod/refine-antd";
import {ButtonProps} from "antd/es/button/button";

import {exportCsv, ExportCsvParams} from "services/csv/export";
import {useCallback} from "react";

export type ExportButtonProps = Omit<ButtonProps, "onClick"> & {
    onData?: () => ExportCsvParams | Promise<ExportCsvParams>;
}

export function ExportButton({onData, ...props}: ExportButtonProps) {
    const clickHandler = useCallback(async () => {
        if (onData == null) {
            return;
        }
        exportCsv(await onData());
    }, [onData]);

    return (
        <Button
            {...props}
            onClick={clickHandler}
            icon={<CloudUploadOutlined/>}
            shape="circle"
            type="text"
        />
    );
}
