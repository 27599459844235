import React, {ReactNode, useCallback, useMemo, useState} from "react";
import {Button, Empty, useTable} from "@pankod/refine-antd";
import {ProCard, ProColumns, ProTable} from "@ant-design/pro-components";
import {useCustom, useApiUrl} from "@pankod/refine-core";
import {CheckCircleFilled, EditOutlined} from "@ant-design/icons";

import {
    ChartAccount,
    ChartAccountType,
    ChartAccountCategories,
} from "services/api/interface/ChartAccount";
import {enumToOptions} from "utils/enumToOptions";
import {getEnumLabel} from "utils/getEnumLabel";
import {tableColumn} from "utils/tableColumn";
import {ProPageContainer} from "components/layout/ProPageContainer";
import {COAModal} from "components/COA/COAModal";

const chartAccountTypeOptions = enumToOptions(ChartAccountType);

export const COAList: React.FC = () => {
    const [editingId, setEditingId] = useState<string | undefined>();

    const {
        tableProps,
        tableQueryResult,
        filters: tableFilters,
        sorter: tableSorter
    } = useTable<ChartAccount>({
        syncWithLocation: true,
    });

    const apiUrl = useApiUrl();
    const {data: filtersData, refetch: refetchFilters} = useCustom({
        url: `${apiUrl}/account/coa/_refdata`,
        method: "get",
        config: {
            query: {
                field: ["acc_desc", "acc_type", "category"],
            },
        },
    });

    const onClose = useCallback((status: "ok" | "cancel") => {
        setEditingId(undefined);

        if (status === "ok") {
            refetchFilters();
            tableQueryResult.refetch();
        }
    }, []);

    const defaultColumnProps = useMemo(() => {
        return {
            filtersRefdata: filtersData?.data ?? {},
            defaultSorter: tableSorter,
            defaultFilters: tableFilters
        };
    }, [filtersData, tableSorter, tableFilters]);

    const columns: ProColumns<ChartAccount>[] = useMemo(() => [
        tableColumn({
            dataIndex: "acc_desc",
            title: "Description",
            ...defaultColumnProps
        }),
        tableColumn({
            dataIndex: "acc_type",
            title: "Type",
            enumForFilters: ChartAccountType,
            width: "13%",
            renderText: (val) => {
                return chartAccountTypeOptions.find((it) => it.value === val)?.label ?? val;
            },
            ...defaultColumnProps
        }),
        tableColumn({
            dataIndex: "category",
            title: "Category",
            enumForFilters: ChartAccountCategories,
            width: "13%",
            renderText: (val) => getEnumLabel(ChartAccountCategories, val),
            ...defaultColumnProps
        }),
        tableColumn({
            dataIndex: ["ef", "description"],
            title: "EF",
            defaultSorter: tableSorter,
            width: "20%"
        }),
        tableColumn({
            dataIndex: "ignored",
            title: "Ignored",
            sorter: false,
            render: (_: ReactNode, record: ChartAccount) => {
                if (record.ignored) {
                    return <div style={{textAlign: "center"}}>
                        <CheckCircleFilled style={{fontSize: 20, color: "gray"}}/>
                    </div>
                }

                return null;
            }
        }),
        {
            title: "Action",
            dataIndex: "action",
            render: (_: ReactNode, record: ChartAccount) => <Button
                icon={<EditOutlined />}
                type="link"
                size="small"
                onClick={() => {
                    setEditingId(record.id);
                }}
            />,
            width: 88,
        }
    ], [defaultColumnProps, tableSorter]);

    const noAccounts = useMemo(() => {
        return !tableProps.loading && tableFilters?.length === 0 && tableProps.dataSource?.length === 0
    }, [tableFilters?.length, tableProps.dataSource?.length, tableProps.loading]);

    const loading = useMemo(() => {
        // On refresh isPreviousData is set to false, on sorting it is set to true
        return tableProps.loading || (tableQueryResult.isFetching && !tableQueryResult.isPreviousData);
    }, [tableProps.loading, tableQueryResult.isFetching, tableQueryResult.isPreviousData]);

    return (
        <ProPageContainer>
            <ProCard>
                {!noAccounts && (
                    <ProTable<ChartAccount>
                        {...tableProps}
                        loading={loading}
                        scroll={{x: undefined}}
                        rowKey="id"
                        headerTitle
                        search={false}
                        options={{
                            reload: () => {
                                tableQueryResult.refetch();
                            }
                        }}
                        columns={columns}
                        locale={{
                            emptyText: <Empty
                                description={tableQueryResult.data == null ? "" : "No items to display. Adjust your filters."}
                            />
                        }}
                    />
                )}
                {noAccounts && (
                    <Empty
                        style={{paddingTop: "6.5rem", paddingBottom: "6.5rem"}}
                        description="No items to display. Process your first batch to populate accounts."
                    />
                )}
                <COAModal id={editingId} onClose={onClose}/>
            </ProCard>
        </ProPageContainer>
    );
}
