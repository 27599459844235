import {useCallback} from "react";
import {Checkbox, Space, Typography} from "@pankod/refine-antd";
import {CheckboxChangeEvent} from "antd/es/checkbox";

const {Link} = Typography;

export type PrivacyPolicyProps = {
    onChange?: Function;
    value?: boolean;
}

export const PrivacyPolicy = ({onChange, value}: PrivacyPolicyProps) => {
    const onTermsChange = useCallback((val: CheckboxChangeEvent) => {
        onChange?.(val.target.checked);
    }, [onChange]);

    return (
        <Space direction="vertical" size={4}>
            <Link href="https://www.thebigtoolbox.com.au/privacy" target="_blank">Privacy Policy</Link>
            <Checkbox
                checked={value}
                onChange={onTermsChange}
            >
                I agree to the <Link href="https://www.thebigtoolbox.com.au/terms" target="_blank">Terms and
                Conditions</Link>
            </Checkbox>
        </Space>
    )
}
