import {useMemo} from "react";
import {Col, Form, Input, Row, Select} from "@pankod/refine-antd";
import {useGetIdentity} from "@pankod/refine-core";
import {DefaultOptionType} from "rc-select/lib/Select";
import {DateTime} from "luxon";

import {
    BatchDataType,
    BatchesWithPeriod,
    BatchMonthlyPeriod,
    BatchPeriodType,
    BatchPostAnalysis,
    BatchQuarterlyPeriod,
    BatchTarget
} from "services/api/interface/Batch";
import {FormContainer} from "components/MultiStep/FormContainer";
import {enumToOptions} from "utils/enumToOptions";
import {useFormWatch, useAfterMountEffect} from "hooks";

const batchDataTypeOptions = enumToOptions(BatchDataType);
const batchPostAnalysisOptions = enumToOptions(BatchPostAnalysis);
const batchPeriodTypeOptions = enumToOptions(BatchPeriodType);

export type BatchPreferencesProps = {
    readonly?: boolean;
}
export const BatchPreferences = ({readonly = false}: BatchPreferencesProps) => {
    const form = Form.useFormInstance();
    const reportingPeriodType: BatchPeriodType = useFormWatch("reporting_period_type", form);
    const {data: user, isLoading} = useGetIdentity();
    const batchType = useFormWatch("type", form);

    const targetOptions = useMemo(() => {
        if (isLoading) {
            return undefined;
        }
        const result = enumToOptions(BatchTarget);
        if (!user.sfUser) {
            return result.filter((it) => it.value !== BatchTarget.Salesforce)
        }
        else {
            return result;
        }
    }, [user]);

    const reportingYearOptions = useMemo(() => {
        const res: DefaultOptionType[] = [];
        const currentYear = DateTime.now().year;
        for (let i = currentYear; i > currentYear - 11; i--) {
            res.push({label: i, value: i});
        }
        return res;
    }, []);

    const reportingPeriodOptions = useMemo(() => {
        switch (reportingPeriodType) {
            case BatchPeriodType["Monthly"]:
                return enumToOptions(BatchMonthlyPeriod);
            case BatchPeriodType["Quarterly"]:
                return enumToOptions(BatchQuarterlyPeriod);
            default:
                return [];
        }
    }, [reportingPeriodType]);

    const showPeriod = useMemo(() => {
        return BatchesWithPeriod.includes(batchType);
    }, [batchType]);

    useAfterMountEffect(() => {
        switch (reportingPeriodType) {
            case BatchPeriodType["Monthly"]:
                form.setFieldValue("reporting_period", DateTime.now().month);
                break;
            case BatchPeriodType["Quarterly"]:
                form.setFieldValue("reporting_period", DateTime.now().quarter);
                break;
            default:
                form.resetFields(["reporting_period"]);
        }
    }, [reportingPeriodType]);

    return (
        <FormContainer>
            <Form.Item
                name="name"
                label="Name"
                rules={[{required: true}]}
                required={!readonly}
                labelAlign="right"
            >
                <Input disabled={readonly}/>
            </Form.Item>
            <Form.Item
                name="type"
                label="Data Type"
                tooltip="This setting provides a guidance on how we process the uploaded data."
                rules={[{required: true}]}
                required={!readonly}
                labelAlign="right"
            >
                <Select options={batchDataTypeOptions} disabled={readonly}/>
            </Form.Item>
            <Form.Item
                name="target"
                label="Target"
                tooltip="Set the target system where the processed data needs to go. If your system is not on the list, choose Export."
                rules={[{required: true}]}
                required={!readonly}
                labelAlign="right"
            >
                <Select
                    options={targetOptions}
                    loading={isLoading}
                    disabled={readonly}
                    notFoundContent={isLoading ? "Loading..." : undefined}
                />
            </Form.Item>
            <Form.Item
                name="post_analysis"
                label="Post Analysis"
                tooltip="Data can be pushed to the target system automatically, choose manual if you prefer to review prior to the push."
                rules={[{required: true}]}
                required={!readonly}
                labelAlign="right"
            >
                <Select options={batchPostAnalysisOptions} disabled={readonly}/>
            </Form.Item>
            {showPeriod && (
                <>
                    <Form.Item
                        name="reporting_period_type"
                        label="Reporting Period"
                        tooltip="Select the emissions reporting period."
                        labelAlign="right"
                        rules={[{required: true}]}
                        required={!readonly}
                    >
                        <Select options={batchPeriodTypeOptions} disabled={readonly}/>
                    </Form.Item>
                    <Row justify="space-between">
                        <Col offset={4} span={12}>
                            <Form.Item
                                name="reporting_year"
                                label="Year"
                                labelAlign="right"
                                rules={[{required: true}]}
                                required={!readonly}
                            >
                                <Select options={reportingYearOptions} style={{width: 80}} disabled={readonly}/>
                            </Form.Item>
                        </Col>
                        {reportingPeriodType !== BatchPeriodType["Annual"] && (
                            <Col span={8}>
                                <Form.Item
                                    name="reporting_period"
                                    label="Period"
                                    labelAlign="right"
                                    rules={[{required: true}]}
                                    required={!readonly}
                                >
                                    <Select options={reportingPeriodOptions} disabled={readonly}/>
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                </>
            )}
        </FormContainer>
    )
};
