import {BaseEntity} from "./BaseEntity";
import {Organization} from "./Organization";
import {ReportingGroup} from "./ReportingGroup";

export const AssetOwnerships = ["Owned", "Leased"] as const;
export type AssetOwnership = typeof AssetOwnerships[number];

export const AssetTypes = ["Stationary", "Mobile"] as const;
export type AssetType = typeof AssetTypes[number];

export interface Asset extends BaseEntity {
    organization_id: string;
    organization: Organization;
    name: string;
    type: AssetType;
    identifier: string;
    ownership: AssetOwnership;
    reportingGroup?: ReportingGroup | null;
    city?: string | null;
    stateOrRegion?: string | null;
    country?: string | null;
    occupiedSpace?: number | null;
}
