import {useCallback} from "react";
import {useCookies} from "react-cookie";

type UseTerms = [boolean, (val: boolean) => void];

export const useTerms = (): UseTerms => {
    const [cookies, setCookie] = useCookies(["terms"])

    const setTerms = useCallback(
        (val: boolean) => setCookie("terms", val),
        [setCookie]
    );

    return [cookies.terms === "true", setTerms];
}
