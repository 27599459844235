import {useScope3Chart} from "../../hooks/useScope3Chart";
import {Scope3Chart} from "../Scope3Chart";
import {ChartContainer} from "../ChartContainer";

export type ViewChartProps = {
    batchId?: string;
}
export const ViewChart = ({batchId}: ViewChartProps) => {
    const {isFetching, data} = useScope3Chart({batchId});

    if (batchId == null) {
        return null;
    }
    else {
        return (
            <ChartContainer
                loading={isFetching}
                title="Emissions by Scope 3 Categories"
                data={data}
                noDataText="Data will show up after processing"
                exportOptions={{
                    headers: [["label", "Category"], ["value", "Emissions (tco2e)"]],
                    name: "Scope3Categories"
                }}
            >
                <Scope3Chart data={data ?? []}/>
            </ChartContainer>
        );
    }
};
