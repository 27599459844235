import {UploadFile} from "@pankod/refine-antd";
import {read, utils, WorkBook} from "xlsx";
import {BatchDataType, BatchColumnsType} from "services/api/interface/Batch";

export type WorksheetParseResult = {
    name: string;
    valid: boolean;
    json: any;
};

export type ParseFileResult = {
    valid: boolean;
    message?: string;
    worksheets?: Record<string, WorksheetParseResult>;
    SheetNames?: string[];
};

export type ParseFilePayload = {
    file: UploadFile,
    type: BatchDataType,
    batchColumns: BatchColumnsType,
}

export const parseFile = async ({batchColumns, file, type}: ParseFilePayload): Promise<ParseFileResult> => {
    if (file == null || file.originFileObj == null) {
        return {
            valid: false,
            message: "No file to parse"
        };
    }

    const fileArrBuff = await file.originFileObj.arrayBuffer();
    let wb: WorkBook;
    try {
        wb = read(fileArrBuff);
    }
    catch (e: any) {
        return {
            valid: false,
            message: "This file seems corrupt, please correct and try again."
        }
    }

    const {SheetNames} = wb;
    const worksheets: Record<string, WorksheetParseResult> = {};
    wb.SheetNames.forEach((name) => {
        const json = utils.sheet_to_json(wb.Sheets[name]!);
        const error = checkSheetRows(batchColumns, json, type);
        const valid = error == null;
        worksheets[name] = {valid, name, json};
    });
    const valid = SheetNames.some((name) => worksheets[name]!.valid);
    const message = valid ? undefined : "No valid worksheets found in the file";

    return {
        valid,
        message,
        worksheets,
        SheetNames,
    }
}


const checkSheetRows = (batchColumns: BatchColumnsType, rows: any[], type: BatchDataType): string | undefined | void => {
    if (batchColumns[type] == null) {
        return `Missing column mappings for "${type}" batch type`;
    }
    if (rows.length < 2) {
        return "Worksheet is expected to have at least 2 rows including the header row";
    }

    const importantColumns = Object.entries(batchColumns[type]).filter(([, {optional}]) => !optional);

    const [firstRow] = rows;
    const columns = Object.keys(firstRow);
    if (columns.length < importantColumns.length) {
        return `Worksheet is expected to have at least ${importantColumns} columns, found ${columns.length}`;
    }
}
