import {useEffect, useMemo} from "react";
import {Modal} from "antd";
import {Col, Form, Row} from "@pankod/refine-antd";
import {SelectX} from "../SelectX";
import {SFEmissionFactorSet} from "services/api/interface/SFEmissionFactorSet";
import {useResourceSelect} from "hooks/useResourceSelect";
import {CommonModalProps} from "./interfaces";
import {useModalSyncForm} from "./useModalSyncForm";

export function LedgerSyncModal(props: CommonModalProps) {
    const {
        open,
        ...rest
    } = props;
    const {modalProps, formProps} = useModalSyncForm({...rest, open});

    const form = formProps.form!;

    const {listQueryResult: SFQueryResult, selectProps: SFEmSelectProps} = useResourceSelect<SFEmissionFactorSet>({
        resource: "account/SFEmissionFactorSet",
        fieldNames: {label: "Name", value: "Id"},
        queryEnabled: open,
    });

    const {selectProps: sourceEmSelectProps} = useResourceSelect({
        resource: "ef/set",
        fieldNames: {label: "name", value: "id"},
        queryEnabled: open,
    });

    // Set salesforce emission factor
    useEffect(() => {
        if (!open) {
            return;
        }

        const SFSetItems = SFQueryResult.data?.data ?? [];
        const defaultSetItem = SFSetItems.reduce<SFEmissionFactorSet | undefined>((prev, current) => {
            return (prev != null && prev.EmissionFactorUpdateYear > current.EmissionFactorUpdateYear) ? prev : current
        }, undefined);

        if (defaultSetItem != null && form.getFieldValue("sfEmissionFactorSetId") == null) {
            form.setFieldValue("sfEmissionFactorSetId", defaultSetItem.Id);
        }
    }, [form.getFieldValue, form.setFieldValue, open, SFQueryResult.data?.data]);

    const noSFEm = useMemo(() => {
        return !SFQueryResult.isFetching && (SFQueryResult.data == null || SFQueryResult.data?.data.length === 0);
    }, [SFQueryResult.isFetching, SFQueryResult.data]);

    return (
        <Modal
            {...modalProps}
            okButtonProps={{...modalProps.okButtonProps, disabled: noSFEm}}
        >
            <Form {...formProps}>
                <Row style={{marginTop: "1.5rem"}}>
                    <Col span={20} offset={2}>
                        <Form.Item
                            name="sfEmissionFactorSetId"
                            label="Salesforce Emissions Factor Set"
                            tooltip="Select the Salesforce Procurement Emissions Factor Set you would like to use"
                            rules={[{required: true}]}
                            validateStatus={noSFEm ? "error" : undefined}
                            help={noSFEm ? "No records found, check the factors exists and matches your corporate currency." : undefined}
                        >
                            <SelectX {...SFEmSelectProps}/>
                        </Form.Item>
                        <Form.Item
                            name="sourceFactorSetId"
                            label="Source"
                            tooltip="Select the source of this factor set"
                            rules={[{required: true}]}
                        >
                            <SelectX {...sourceEmSelectProps}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}
