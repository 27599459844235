import {ObjectSchema} from "yup";

import {Batch, BatchDataType} from "services/api/interface/Batch";
import {getFuelUsageSchema, getElectricityUsageSchema, assetSchemaBatch} from "services/yupSchemas";
import {resolveLineVariables} from "utils/batch/resolveLineVariables";

export type CheckBatchFilePayload = {
    sheetRows?: Record<string, any>[];
    columnMappings: Batch["column_mappings"]
    type: BatchDataType,
    token: string,
}

export type CheckBatchFileResult = string | undefined;

type SchemaMapping = Partial<Record<
    BatchDataType,
    ObjectSchema<any> | ((token: string) => Promise<ObjectSchema<any>>)
>>;

const schemaMapping: SchemaMapping = {
    [BatchDataType["Assets"]]: assetSchemaBatch,
    [BatchDataType["Fuel Usage"]]: getFuelUsageSchema,
    [BatchDataType["Electricity Usage"]]: getElectricityUsageSchema,
};

export async function checkBatchFile(
    {sheetRows, columnMappings, type, token}: CheckBatchFilePayload
): Promise<CheckBatchFileResult> {
    const schemaDefiner = schemaMapping[type];
    const targetSchema = await (typeof schemaDefiner === "function" ? schemaDefiner(token) : schemaDefiner)
    if (targetSchema == null) {
        return undefined;
    }

    if (sheetRows == null) {
        return "Sheet rows missing";
    }

    let lineIndex = 0;
    try {
        sheetRows.forEach((line: Record<string, any>, index) => {
            const resolvedLine = resolveLineVariables(line, columnMappings);
            lineIndex = index;
            targetSchema.validateSync(resolvedLine);
        });
    }
    catch (e: any) {
        const error = e.message ?? String(e);
        return `Error in asset batch file at line ${lineIndex + 2}: ${error}`;
    }

    return undefined;
}
