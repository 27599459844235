import React, {useCallback, useState} from "react";
import {debounce} from "lodash";
import {SelectProps, Typography} from "@pankod/refine-antd";
import {BaseRecord, CrudFilter, CrudSorting} from "@pankod/refine-core";

import {useResourceSelect} from "hooks/useResourceSelect";
import {SelectX} from "../SelectX";

type LookUpProps<TData extends BaseRecord = BaseRecord> = Omit<SelectProps<TData, TData>, "fieldNames"> & {
    resource: string;
    minSearch?: number | boolean;
    sort?: CrudSorting;
    fieldNames: {label: string, value: string};
    recordIsValue?: boolean;
};

export const LookUp: React.FC<LookUpProps> = <TData extends BaseRecord = BaseRecord>(props: LookUpProps<TData>) => {
    const {
        resource,
        minSearch = 3,
        sort,
        ...rest
    } = props;

    const [search, setSearch] = useState<CrudFilter | undefined>();
    const {label: labelKey} = rest.fieldNames;

    const {listQueryResult, selectProps: lookUpSelectProps} = useResourceSelect<TData>({
        resource,

        queryEnabled: search != null,
        filters: search != null ? [search] : [],
        sort,
    });

    const onSearch = useCallback((value: string) => {
        if (value.length === 0) {
            setSearch(undefined);
            return;
        }
        if (minSearch && value.length < minSearch) {
            return;
        }
        setSearch({
            field: labelKey,
            operator: "contains",
            value,
        });
    }, [minSearch, labelKey]);

    return (
        <SelectX
            {...rest}
            {...lookUpSelectProps}
            showSearch
            filterOption={false}
            showArrow={listQueryResult.isFetching}
            loading={listQueryResult.isFetching}
            onSearch={debounce(onSearch, 300)}
            notFoundContent={null}
            dropdownMatchSelectWidth={false}
            dropdownRender={(menu) => listQueryResult.isFetching
                ? <div style={{padding: "5px 12px"}}>
                    <Typography.Text type="secondary">
                        Searching...
                    </Typography.Text>
                </div>
                : menu
            }
        />
    );
}
