import {useCallback} from "react";
import {Button, Form, Input} from "@pankod/refine-antd";

import {useTerms} from "hooks/useTerms";
import {AgreeToTermsField, EmailField, PasswordField} from "./fields";
import {TabFormProps} from "./types";

export const LoginForm = ({onSubmit, isLoading}: TabFormProps) => {
    const [form] = Form.useForm();
    const [agree, setAgree] = useTerms()

    const finishHandler = useCallback(async (values: any) => {
        setAgree(values.agree);
        await onSubmit(values);
    }, [setAgree, onSubmit]);

    return (
        <Form
            form={form}
            initialValues={{type: "email", agree}}
            layout="vertical"
            size="large"
            disabled={isLoading}
            validateTrigger="onBlur"
            onFinish={finishHandler}
        >
            <Form.Item name="type" hidden>
                <Input/>
            </Form.Item>
            <EmailField/>
            <PasswordField/>
            <AgreeToTermsField/>
            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    style={{width: "100%"}}
                    loading={isLoading}
                >
                    Login
                </Button>
            </Form.Item>
        </Form>
    )
}
