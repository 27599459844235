import {useMemo} from "react";
import {useMenu, useNavigation, useRefineContext, useRouterContext} from "@pankod/refine-core";
import {ConfigProvider} from "@pankod/refine-antd";
import {ProLayout} from "@ant-design/pro-components";
import {DashboardOutlined} from "@ant-design/icons";
import {defaultValidateMessages} from "rc-field-form/lib/utils/messages";
import {ValidateMessages} from "rc-field-form/lib/interface";
import enGB from "antd/lib/locale/en_GB";

import {RightContent} from "./RightContent";
import {ProRoute, resourceToProRoute} from "./resourceToProRoute";
import {APP_TITLE} from "../../constants";

const validateMessages: ValidateMessages = {
    ...defaultValidateMessages,
    required: "${label} is required",
    types: {
        ...defaultValidateMessages.types,
        email: "${label} must be a valid email address"
    }
}

export const Layout = ({children}: any) => {
    const {menuItems} = useMenu();
    const {list} = useNavigation();
    const {useLocation} = useRouterContext();
    const location = useLocation();
    const {hasDashboard} = useRefineContext();

    const menuRoutes: ProRoute[] = useMemo(() => {
        const result = menuItems.map(resourceToProRoute);
        if (hasDashboard) {
            result.unshift({
                path: "/",
                icon: <DashboardOutlined/>,
                name: "Dashboard",
                resource: ""
            });
        }
        return result;
    }, [hasDashboard, menuItems]);

    return (
        <ConfigProvider form={{validateMessages}} locale={enGB}>
            <ProLayout
                siderWidth={240}
                logo={<img src="/logo.svg" alt="Smart DataHub Logo"/>}
                layout="mix"
                title={APP_TITLE}
                rightContentRender={() => <RightContent/>}
                location={location}
                route={{
                    path: "/",
                    routes: menuRoutes
                }}
                menu={{collapsedShowGroupTitle: true}}
                menuItemRender={(item, dom) => {
                    return <div onClick={() => list(item.resource)}>{dom}</div>
                }}
                headerTitleRender={(logo, title) => {
                    return (
                        <div style={{
                            whiteSpace: "nowrap",
                            display: "flex",
                            alignItems: "center",
                        }}>
                            {logo}
                            {title}
                        </div>
                    );
                }}
            >
                {children}
            </ProLayout>
        </ConfigProvider>
    )
}
