export function getEnumLabel<E extends object, K extends keyof E>(enm: E, value?: E[K]): K | undefined {
    if (value == null) {
        return undefined;
    }

    const entry = Object.entries(enm).find(([, enumValue]) => String(enumValue) === String(value));
    if (entry == null) {
        return undefined
    }
    else {
        return entry[0] as any
    }
}
