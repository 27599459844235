import axios from "axios";
import {Refine} from "@pankod/refine-core";
import {
    notificationProvider,
    ReadyPage,
    ErrorComponent,
} from "@pankod/refine-antd";

import dataProvider from "@pankod/refine-simple-rest";
import routerProvider from "@pankod/refine-react-router-v6";
import {RefineKbarProvider} from "@pankod/refine-kbar";
import {ColorModeContextProvider} from "contexts";
import {Login} from "pages/Login";
import {OffLayoutArea} from "components/offLayoutArea";
import {TableOutlined, UserOutlined} from "@ant-design/icons";

import {API_BASE_URL} from "./services/api/constants";
import {useAuthProvider} from "./authProvider";
import {Layout} from "./components/layout";
import {AccountSetupPage} from "./pages/AccountSetup";
import {BatchList} from "./pages/BatchList";
import {BatchView} from "./pages/BatchView";
import {BatchUpload} from "./pages/BatchUpload";
import {Dashboard} from "./pages/Dashboard";
import {COAList} from "./pages/COAList";
import {AssetList} from "./pages/AssetList";
import {AssetView} from "./pages/AssetView";

import "@pankod/refine-antd/dist/reset.css";

function App() {
    const authProvider = useAuthProvider();

    return (
        <ColorModeContextProvider>
            <RefineKbarProvider>
                <Refine
                    options={{
                        disableTelemetry: true,
                        reactQuery: {
                            clientConfig: {
                                defaultOptions: {
                                    queries: {
                                        retry: false
                                    }
                                }
                            }
                        }
                    }}
                    DashboardPage={Dashboard}
                    dataProvider={dataProvider(`${API_BASE_URL}`, axios)}
                    notificationProvider={notificationProvider}
                    Layout={Layout}
                    ReadyPage={ReadyPage}
                    catchAll={<ErrorComponent/>}
                    routerProvider={{
                        ...routerProvider,
                        routes: [
                            {path: "/account/setup", element: <AccountSetupPage/>, layout: true},
                            {path: "/signup", element: <Login/>}
                        ]
                    }}
                    authProvider={authProvider}
                    LoginPage={Login}
                    OffLayoutArea={OffLayoutArea}
                    resources={[
                        {
                            name: "data-management",
                            icon: <TableOutlined/>,
                            options: {
                                label: "Data Management",
                            }
                        },
                        {
                            name: "batch",
                            parentName: "data-management",
                            options: {label: "Batches"},
                            list: BatchList,
                            show: BatchView,
                            create: BatchUpload
                        },
                        {
                            name: "account",
                            icon: <UserOutlined/>,
                            options: {
                                label: "Account",
                            }
                        },
                        {
                            name: "setup",
                            parentName: "account",
                            options: {label: "Setup"},
                            list: () => null
                        },
                        {
                            name: "account/coa",
                            parentName: "account",
                            options: {label: "Chart of Accounts", route: "coa"},
                            list: COAList,
                        },
                        {
                            name: "account/asset",
                            parentName: "account",
                            options: {label: "Assets", route: "asset"},
                            list: AssetList,
                            show: AssetView,
                        }
                    ]}
                />
            </RefineKbarProvider>
        </ColorModeContextProvider>
    );
}

export default App;
