import {useCallback, useMemo, useState} from "react";
import {DateTime} from "luxon";
import {CrudFilters, useDataProvider, useNavigation, useNotification, useResource} from "@pankod/refine-core";
import {StepProps} from "@pankod/refine-antd";
import {ProCard} from "@ant-design/pro-components";

import {BatchUploadStep} from "./BatchUploadStep";
import {ColumnMappingTable} from "./ColumnMappingTable";
import {MultiStep, StepValidationResult} from "components/MultiStep";
import {BatchFormData} from "./interface";
import {BatchPreferences} from "./BatchPreferences";
import {
    BatchDataType,
    BatchPeriodType,
    BatchSourceType,
    BatchColumnsType,
} from "services/api/interface/Batch";
import {ParseFileResult} from "services/workers/parseFile";
import {parseFileTaskManager, checkBatchFileTaskManager} from "services/workers";
import {API} from "services/api";
import {Auth} from "services/auth";
import {SourceType} from "./SourceType";
import {ProPageContainer} from "components/layout/ProPageContainer";
import {FormContainer} from "components/MultiStep/FormContainer";
import {getEnumLabel} from "utils/getEnumLabel";
import {calculateBatchPeriod} from "./helpers/calculateBatchPeriod";

const STEPS: StepProps[] = [
    {title: "Batch Info"},
    {title: "Batch Source"},
    {title: "Review"},
];

const LABEL_SPAN = 8;

export const BatchUpload = () => {
    const [parseResult, setParseResult] = useState<ParseFileResult>();
    const {open} = useNotification();
    const {show} = useNavigation();
    const {resource} = useResource();
    const [batchType, setBatchType] = useState<BatchDataType>();
    const [batchColumns, setBatchColumns] = useState<BatchColumnsType>();

    const dataProvider = useDataProvider();
    const defaultDataProvider = dataProvider();

    const initialValues = useMemo<Partial<BatchFormData>>(() => ({
        name: DateTime.now().toFormat("'Batch' L/d/yyyy h:mma"),
        reporting_period_type: BatchPeriodType["Monthly"],
        reporting_year: DateTime.now().year,
        reporting_period: DateTime.now().month,
        source: BatchSourceType["File Upload"]
    }), []);

    const stepValidation = useCallback(async function (step: number, values: BatchFormData): Promise<StepValidationResult | void> {
        const {type, file, column_mappings = {}, selected_sheet} = values;
        switch (step) {
            case 0:
                setBatchType(type);
                setBatchColumns(await API.getBatchColumns());

                // Check on overlapping batches
                const batchPeriod = calculateBatchPeriod(values.reporting_period_type, values.reporting_year, values.reporting_period);
                if (batchPeriod == null) {
                    return;
                }

                const filters: CrudFilters = [
                    {field: "periodStart", operator: "lte", value: batchPeriod.periodEnd},
                    {field: "periodEnd", operator: "gte", value: batchPeriod.periodStart},
                    {field: "type", operator: "eq", value: values.type}
                ];

                const response = await defaultDataProvider.getList({resource: "batch", filters});
                if (response.data?.length > 0) {
                    return {
                        valid: false,
                        warning: `"${getEnumLabel(BatchDataType, values.type)}" batch exists which overlaps specified reporting period. Do you want to continue?`
                    };
                }
                return;
            case 1:
                if (file == null) {
                    return {
                        valid: false,
                        error: "Please select a file"
                    };
                }

                try {
                    const result = await parseFileTaskManager.startTask({
                        file,
                        type: batchType!,
                        batchColumns: batchColumns!
                    });
                    if (!result.valid) {
                        return {
                            valid: false,
                            error: result.message
                        };
                    }
                    setParseResult(result);

                    return {valid: true};
                }
                catch (e: any) {
                    return {
                        valid: false,
                        error: e.message ?? String(e)
                    };
                }

            case 2:
                // Check if all needed columns are mapped
                const columnsMeta = batchColumns![batchType!];

                const allColumnsMapped = Object.keys(columnsMeta).every((key) => {
                    return columnsMeta[key]!.optional || (column_mappings as any)[key] != null
                });
                let error = !allColumnsMapped ? "Not all required columns are mapped" : undefined;

                // Check values
                if (error == null) {
                    error = await checkBatchFileTaskManager.startTask({
                        sheetRows: parseResult?.worksheets?.[selected_sheet]?.json,
                        columnMappings: column_mappings,
                        type: batchType!,
                        token: Auth.jwt.get()!
                    });
                }

                return {
                    valid: error == null,
                    error,
                };
        }
    }, [batchColumns, batchType, defaultDataProvider, parseResult]);

    return (
        <ProPageContainer>
            <ProCard>
                <MultiStep
                    authenticated
                    labelSpan={LABEL_SPAN}
                    steps={STEPS}
                    initialValues={initialValues}
                    updateFn={async (values) => {
                        let id: string;
                        try {
                            const response = await API.createBatch(values, values.file!.originFileObj!);
                            id = response.id
                        }
                        catch (e: any) {
                            open?.({
                                type: "error",
                                message: e.response?.data ?? e.message
                            });

                            return;
                        }
                        open?.({
                            type: "success",
                            message: "Batch successfully created!"
                        });
                        show(resource.route!, id);
                        // Refetch batch columns
                        API.getBatchColumns(true);
                    }}
                    stepValidation={stepValidation}
                >
                    {({step}, renderAlert) => <>
                        {step === 0 && <BatchPreferences/>}
                        {step === 1 && <BatchUploadStep labelSpan={LABEL_SPAN}/>}
                        {step === 2 && <>
                            <BatchPreferences readonly/>
                            <SourceType/>
                            <ColumnMappingTable
                                parseResult={parseResult}
                                labelSpan={LABEL_SPAN}
                                batchColumns={batchColumns}
                            />
                            {renderAlert(FormContainer)}
                        </>}
                    </>}
                </MultiStep>
            </ProCard>
        </ProPageContainer>
    );
}
