import {BaseEntity} from "./BaseEntity";
import {Organization} from "./Organization";

export enum BatchDataType {
    "Purchase Ledger" = "purchase-ledger",
    "General Ledger" = "general-ledger",
    "Assets" = "assets",
    "Fuel Usage" = "fuel-usage",
    "Electricity Usage" = "electricity-usage",
}

export const BatchesWithPeriod = [
    BatchDataType["General Ledger"],
    BatchDataType["Purchase Ledger"],
    BatchDataType["Fuel Usage"],
    BatchDataType["Electricity Usage"]
];

export enum BatchTarget {
    Export = "export",
    Salesforce = "sf"
}

export enum BatchPostAnalysis {
    Manual = "manual",
    Automatic = "auto"
}

export enum BatchPeriodType {
    "Annual" = "annual",
    "Monthly" = "monthly",
    "Quarterly" = "quarterly"
}

export enum BatchMonthlyPeriod {
    "January" = 1,
    "February" = 2,
    "March" = 3,
    "April" = 4,
    "May" = 5,
    "June" = 6,
    "July" = 7,
    "August" = 8,
    "September" = 9,
    "October" = 10,
    "November" = 11,
    "December" = 12,
}

export enum BatchQuarterlyPeriod {
    "Q1" = 1,
    "Q2" = 2,
    "Q3" = 3,
    "Q4" = 4,
}

export enum BatchSourceType {
    "File Upload" = "file-upload"
}

export enum BatchStatus {
    "Upload Pending" = "pending",
    Uploaded = "uploaded",
    Processing = "processing",
    "Accounts Processed" = "accProcessed",
    Processed = "processed",
    "Sync Pending" = "syncPending",
    "Sync In Progress" = "syncInProgress",
    "Sync Complete" = "syncComplete",
    "Sync Incomplete" = "syncIncomplete",
    Error = "error",
}

export interface BatchStats {
    account_count?: number;
    row_count: number;
    total_spend?: number;
    total_spend_included?: number;
    total_spend_ignored?: number;
    total_mobile_assets?: number;
    total_stationary_assets?: number;
    total_occupied_area?: number;
    total_liquid_fuel_quantity?: number;
    total_gas_fuel_quantity?: number;
    total_quantity?: number;
}

export interface Batch extends BaseEntity {
    batch_id: string;
    sandbox: boolean;
    success?: number;
    error?: number;
    org_id: string;
    org: Organization;
    source: BatchSourceType;
    target: BatchTarget;
    type: BatchDataType;
    status: BatchStatus;
    post_analysis: BatchPostAnalysis;
    reporting_period_type?: BatchPeriodType;
    reporting_year?: number;
    reporting_period?: BatchMonthlyPeriod | BatchQuarterlyPeriod;
    selected_sheet: string;
    orig_file_name: string;
    name: string;
    s3_key?: string;
    stats?: BatchStats;
    column_mappings: Record<string, string>;
}


export type BatchColumnsType = {
    [key in BatchDataType]: {
        [key: string]: {
            alias: string[],
            label: string,
            optional?: boolean
        }
    }
}
