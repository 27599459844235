import React, {useCallback, useEffect, useMemo, useState} from "react";
import {PageContainer, ProCard} from "@ant-design/pro-components";
import {theme, Space, Typography, Statistic, Divider, Button, Row, Col, Card} from "@pankod/refine-antd";
import {useGetIdentity} from "@pankod/refine-core";
import {ReloadOutlined} from "@ant-design/icons";
import {DateTime} from "luxon";

import {API} from "services/api";
import {useResourceSelect} from "hooks/useResourceSelect";
import {useStats} from "hooks/useStats";
import {useEmissionChart} from "hooks/useEmissionChart";
import {useScopesChart} from "hooks/useScopesChart";
import {useScope3Chart} from "hooks/useScope3Chart";
import {SelectX} from "components/SelectX";
import {ChartContainer, ChartExportOptions} from "components/ChartContainer";
import {LabelWithHint} from "components/LabelWithHint";
import {Scope3Chart} from "components/Scope3Chart";
import {ScopesChart} from "./ScopesChart";
import {EmissionsChart} from "./EmissionsChart";


const EMPTY = "__EMPTY__";

export const Dashboard: React.FC = () => {
    const {token} = theme.useToken();
    const {data: identity} = useGetIdentity<{ id: number; name: string, avatar: string }>();

    const greetings = useMemo(() => {
        return new Date().getHours() > 12 ? "Good afternoon" : "Good morning";
    }, []);

    const [orgName, setOrgName] = useState<string>();
    useEffect(() => {
        API.fetchOrganization().then((data) => {
            setOrgName(data.name);
        });
    }, []);

    const [groupFilter, setGroupFilter] = useState<string>(EMPTY);
    const groupId = useMemo(() => groupFilter !== EMPTY ? groupFilter : undefined, [groupFilter]);

    const {selectProps: reportingGroupSelectProps} = useResourceSelect({
        resource: "account/reportingGroup",
        fieldNames: {label: "name", value: "id"},
        dropdownMatchSelectWidth: false,
        value: groupFilter,
        onChange: setGroupFilter,
        emptyOption: {name: "All Reporting Groups", id: EMPTY}
    });

    const [assetFilter, setAssetFilter] = useState<string>(EMPTY);
    const assetId = useMemo(() => assetFilter !== EMPTY ? assetFilter : undefined, [assetFilter]);

    const {selectProps: assetSelectProps} = useResourceSelect({
        resource: "account/asset",
        fieldNames: {label: "name", value: "id"},
        dropdownMatchSelectWidth: false,
        value: assetFilter,
        onChange: setAssetFilter,
        emptyOption: {name: "All Assets", id: EMPTY}
    });

    const {
        isFetching: isEmissionsLoading,
        data: emissionsData,
        refetch: refetchEmissions
    } = useEmissionChart({
        groupId: groupId,
        assetId: assetId,
    });

    const {
        isFetching: isScopesLoading,
        data: scopesData,
        refetch: refetchScopes
    } = useScopesChart({
        groupId: groupId,
        assetId: assetId,
    });

    const {
        isFetching: isScope3Loading,
        data: scope3Data,
        refetch: refetchScope3
    } = useScope3Chart({
        from: DateTime.now().startOf("year").toISODate(),
        groupId: groupId,
        assetId: assetId,
    });

    const {data: statsData, isFetching: statsDataLoading} = useStats();

    const refetchAll = useCallback(() => {
        refetchEmissions();
        refetchScopes();
        refetchScope3();
    }, [refetchEmissions, refetchScopes, refetchScope3]);

    const isLoading = useMemo(
        () => isEmissionsLoading || isScopesLoading || isScope3Loading,
        [isEmissionsLoading, isScopesLoading, isScope3Loading]
    );

    const {emissionsExportOptions, scopesExportOptions, scope3ExportOptions} = useMemo(
        () => {
            const year = new Date().getFullYear();
            const group = reportingGroupSelectProps.options?.find((it) => it.id === groupFilter);
            const groupName = group?.name.replace(/\s/g, "_").toLowerCase();
            const asset = assetSelectProps.options?.find((it) => it.id === assetFilter);
            const assetName = asset?.name.replace(/\s/g, "_").toLowerCase();

            return {
                emissionsExportOptions: {
                    headers: [["year", "Year"], ["scope", "Scope"], ["value", "Emissions (tco2e)"]],
                    name: `emissions_over_time-all_time-${groupName}-${assetName}`,
                },
                scopesExportOptions: {
                    headers: [["scope", "Scope"], ["value", "Emissions (tco2e)"]],
                    name: `ytd_scope_breakdown-${year}-${groupName}-${assetName}`,
                },
                scope3ExportOptions: {
                    headers: [["label", "Category"], ["value", "Emissions (tco2e)"]],
                    name: `ytd_scope3-${year}-${groupName}-${assetName}`,
                },
            } as Record<string, ChartExportOptions>
        },
        [reportingGroupSelectProps.options, groupFilter, assetSelectProps.options, assetFilter]
    );

    return (
        <PageContainer
            header={{
                title: (
                    <Space direction="vertical" size={0}>
                        <Typography.Title level={4}>{greetings}, {identity?.name}, have a great day!</Typography.Title>
                        <Typography.Text type="secondary">
                            {orgName}
                        </Typography.Text>
                    </Space>
                ),
                extra: statsData?.data != null && (
                    <Space split={<Divider type="vertical" style={{height: "32px"}}/>}>
                        <Statistic title="Batches" value={statsData.data?.batches}/>
                        <Statistic title="Rows" value={statsData.data?.rows}/>
                    </Space>
                ),
                avatar: {
                    src: identity?.avatar,
                    size: 72
                },
                style: {
                    backgroundColor: token.colorBgBase,
                }
            }}
            ghost={false}
            childrenContentStyle={{padding: ".5rem 0.75rem"}}
        >
            <Row style={{margin: "0.25rem 0 0.75rem"}} gutter={12}>
                <Col md={6}>
                    <Card>
                        <Statistic
                            value={statsData?.data?.spend_included || "-"}
                            title={<LabelWithHint
                                title="YTD GL Spend Processed"
                                hint="Rolled up from processed GL batches."
                            />}
                            prefix="$"
                            precision={2}
                            loading={statsDataLoading}
                        />
                    </Card>
                </Col>
                <Col md={6}>
                    <Card>
                        <Statistic
                            value={statsData?.data?.spend_ignored || "-"}
                            title={<LabelWithHint
                                title="YTD GL Spend Excluded"
                                hint="Rolled up from processed GL batches."
                            />}
                            prefix="$"
                            precision={2}
                            loading={statsDataLoading}
                        />
                    </Card>
                </Col>
                <Col md={6}>
                    <Card>
                        <Statistic
                            value={statsData?.data?.energy_usage_cost || "-"}
                            title={<LabelWithHint
                                title="YTD Energy Usage Cost"
                                hint="Rolled up from processed Fuel and Electricity batches."
                            />}
                            prefix="$"
                            precision={2}
                            loading={statsDataLoading}
                        />
                    </Card>
                </Col>
                <Col md={6}>
                    <Card>
                        <Statistic
                            value={statsData?.data?.gl_spend_energy || "-"}
                            title={<LabelWithHint
                                title="YTD GL Spend Energy"
                                hint="Rolled up from processed GL batches."
                            />}
                            prefix="$"
                            precision={2}
                            loading={statsDataLoading}
                        />
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md={24}>
                    <ProCard
                        headerBordered
                        title="Overview"
                        extra={
                            <Space size={24}>
                                <Typography.Text type="secondary">Filters</Typography.Text>
                                <SelectX {...reportingGroupSelectProps} style={{width: "10.75rem"}}
                                         disabled={isLoading}/>
                                <SelectX {...assetSelectProps} style={{width: "10.75rem"}} disabled={isLoading}/>
                                <Space size={4}>
                                    <Button
                                        icon={<ReloadOutlined color="secondary"/>}
                                        shape="circle"
                                        type="text"
                                        onClick={refetchAll}
                                    />
                                </Space>
                            </Space>
                        }
                        direction="column"
                    >
                        <Row>
                            <Col span={12}>
                                <ChartContainer
                                    loading={isEmissionsLoading}
                                    title="Emissions over time"
                                    data={emissionsData}
                                    exportOptions={emissionsExportOptions}
                                >
                                    <EmissionsChart data={emissionsData ?? []}/>
                                </ChartContainer>
                            </Col>

                            <Col span={12}>
                                <ChartContainer
                                    loading={isScopesLoading}
                                    title="YTD Scope Breakdown"
                                    data={scopesData}
                                    exportOptions={scopesExportOptions}
                                >
                                    <ScopesChart data={scopesData ?? []}/>
                                </ChartContainer>
                            </Col>
                        </Row>

                        <ChartContainer
                            loading={isScope3Loading}
                            title="YTD Scope 3 Categories"
                            data={scope3Data}
                            noDataText="No data to display. Process your first GL batch to populate chart."
                            exportOptions={scope3ExportOptions}
                        >
                            <Scope3Chart data={scope3Data ?? []}/>
                        </ChartContainer>
                    </ProCard>
                </Col>
            </Row>
        </PageContainer>
    )
}
