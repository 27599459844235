import {Form} from "@pankod/refine-antd";

import {PrivacyPolicy} from "../PrivacyPolicy";

export const AgreeToTermsField = () => {
    return (
        <Form.Item
            name="agree"
            rules={[{
                validator: (_, value, cb) => {
                    if (!value) {
                        cb("Please agree with the Terms and Conditions");
                    }
                    else {
                        cb();
                    }
                }
            }]}
        >
            <PrivacyPolicy/>
        </Form.Item>
    )
}
