import React from "react";
import {IResourceItem, ITreeMenu} from "@pankod/refine-core";

export type ProRoute = {
    path?: string;
    name?: string;
    icon?: React.ReactNode;
    resource?: string;
    routes?: ProRoute[];
}

export const resourceToProRoute = ({route, label, name, icon, children}: ITreeMenu & IResourceItem): ProRoute => {
    return {
        path: route,
        name: label,
        resource: name,
        icon,
        routes: children.map(resourceToProRoute),
    }
};
