import {useMemo} from "react";
import {SelectProps} from "antd/es/select";
import {CrudFilters, CrudSorting, useList} from "@pankod/refine-core";

export type UseResourceSelectOptions<Val extends {}, Opt extends {} = Val> = SelectProps<Val, Opt> & {
    resource: string;
    staleTime?: number;
    queryEnabled?: boolean;
    sort?: CrudSorting;
    filters?: CrudFilters;
    emptyOption?: Opt;
}

type UseResourceSelectResult<Val extends {}, Opt extends {} = Val> = {
    listQueryResult: ReturnType<typeof useList<Opt>>;
    selectProps: SelectProps<Val, Opt>;
}

export function useResourceSelect<Val extends {}, Opt extends {} = Val>(props: UseResourceSelectOptions<Val, Opt>): UseResourceSelectResult<Val, Opt> {
    const {
        resource,
        staleTime = 5 * 60 * 1000,
        queryEnabled = true,
        sort,
        filters,
        emptyOption,
        ...rest
    } = props;

    const listQueryResult = useList<Opt>({
        resource,
        config: {
            hasPagination: false,
            sort,
            filters
        },
        queryOptions: {
            staleTime,
            enabled: queryEnabled,
        }
    });

    const options = useMemo(() => {
        const res = emptyOption != null ? [emptyOption] : [];
        if (listQueryResult.data?.data != null) {
            res.push(...listQueryResult.data?.data);
        }
        return res;
    }, [listQueryResult.data?.data]);

    return {
        selectProps: {
            ...rest,
            loading: listQueryResult.isFetching,
            options,
        },
        listQueryResult,
    }
}
