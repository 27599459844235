export const enumToOptions = (enumObj: object) => {
    const result: {value: string | number, label: string}[] = [];

    Object.entries(enumObj).forEach(([label, value]) => {
        if (isNaN(Number(label))) {
            result.push({value, label});
        }
    });

    return result;
}
