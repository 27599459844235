import {useCallback, useMemo} from "react";
import {Modal} from "antd";
import {Col, Form, Input, Row, Select, Skeleton, useForm} from "@pankod/refine-antd";
import {DeepPartial} from "@ant-design/pro-components";

import {Asset, AssetOwnerships, AssetTypes} from "services/api/interface/Asset";
import {assetSchema} from "services/yupSchemas/assetSchema";
import {Countries} from "services/api/interface/AccountInfo";
import {arrayToOptions} from "utils/arrayToOptions";
import {enumToOptions} from "utils/enumToOptions";
import {SelectX} from "../SelectX";
import {useResourceSelect} from "hooks/useResourceSelect";

export type AssetModalProps = {
    action?: "create" | "edit";
    id?: string;
    onClose: (result: "cancel" | "ok") => any;
}

type Validator = (rule: { field: string }, value: any) => Promise<any>;
type RuleWithField = { validator: Validator };

const yupRule: RuleWithField = {
    async validator({field}, value) {
        await assetSchema.validateAt(field, {[field]: value});
    }
}

export function AssetModal({id, onClose, action}: AssetModalProps) {
    const {form, formProps, formLoading, onFinish, queryResult, mutationResult} = useForm<Asset>({
        resource: "account/asset",
        action,
        id,
        redirect: false,
        queryOptions: {
            staleTime: 60 * 5 * 1000
        }
    });

    const type = Form.useWatch("type", form);
    const name = Form.useWatch("name", form);

    const open = useMemo(() => action != null, [action]);
    const okHandler = useCallback(async () => {
        const values = form.getFieldsValue() as Asset;
        if (form.getFieldValue("type") === "Mobile") {
            values.occupiedSpace = null;
        }

        await onFinish(values);
        onClose("ok");
    }, [onFinish, onClose]);

    const cancelHandler = useCallback(() => {
        onClose("cancel");
    }, [onClose]);

    const {selectProps: reportingGroupSelectProps} = useResourceSelect({
        resource: "account/reportingGroup",
        fieldNames: {label: "name", value: "id"},
        showSearch: true,
        filterOption: true,
        optionFilterProp: "name",
        queryEnabled: open,
    });

    const renderModalContent = () => (
        <Form<DeepPartial<Asset>>
            {...formProps}
            labelAlign="left"
            layout="vertical"
            disabled={mutationResult.isLoading}
            validateTrigger="onBlur"
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="name"
                        label="Name"
                        rules={[yupRule as any]}
                        required
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="identifier"
                        label="Identifier"
                        rules={[yupRule as any]}
                        required
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="ownership"
                        label="Ownership"
                        rules={[yupRule as any]}
                        required
                    >
                        <Select options={arrayToOptions(AssetOwnerships)}/>
                    </Form.Item>
                    <Form.Item
                        name="type"
                        label="Asset Type"
                        rules={[yupRule as any]}
                        required
                    >
                        <Select options={arrayToOptions(AssetTypes)}/>
                    </Form.Item>
                    <Form.Item
                        name="occupiedSpace"
                        label="Occupied Area"
                        rules={[yupRule as any]}
                        hidden={type !== "Stationary"}
                    >
                        <Input/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="country"
                        label="Country"
                        rules={[yupRule as any]}
                    >
                        <Select options={enumToOptions(Countries)}/>
                    </Form.Item>
                    <Form.Item
                        name="stateOrRegion"
                        label="State/Region"
                        rules={[yupRule as any]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="city"
                        label="City"
                        rules={[yupRule as any]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="reportingGroup"
                        label="Reporting Group"
                        rules={[yupRule as any]}
                    >
                        <SelectX {...reportingGroupSelectProps} recordIsValue/>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );

    const modalTitle = useMemo(() => {
        if (action === "edit") {
            return queryResult?.isFetching ? "Asset" : name;
        }
        else {
            return "Add Asset";
        }
    }, [queryResult?.isFetching, name]);

    return (
        <Modal
            title={modalTitle}
            open={open}
            onCancel={cancelHandler}
            onOk={okHandler}
            width="50vw"
            bodyStyle={{paddingTop: "1rem"}}
            okButtonProps={{
                disabled: formLoading,
                loading: mutationResult.isLoading
            }}
            cancelButtonProps={{disabled: mutationResult.isLoading}}
        >
            {queryResult?.isFetching && <Skeleton
                loading
                active
                paragraph={{rows: 6}}
            />}
            {!queryResult?.isFetching && renderModalContent()}
        </Modal>
    );
}
