import {Column, ColumnConfig} from "@ant-design/plots";

const chartOptions: Partial<ColumnConfig> = {
    isGroup: true,
    xAxis: {
        label: {
            autoRotate: true,
            autoHide: false,
            autoEllipsis: true
        }
    },
    columnStyle: {
        fillOpacity: 1
    },
    label: false,
    tooltip: {
        formatter: ({value, scope}) => ({
            value: value.toFixed(2),
            name: scope,
        }),
    },
};

export function EmissionsChart({data}: { data: Array<any> }) {
    return (
        <Column
            {...chartOptions}
            data={data}
            xField="year"
            yField="value"
            seriesField="scope"
        />
    );
}
