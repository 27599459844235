import {useBreadcrumb} from "@pankod/refine-core";
import {theme} from "@pankod/refine-antd";
import {PageContainer} from "@ant-design/pro-components";
import {Link} from "@pankod/refine-react-router-v6";

export type ProPageContainerProps = {
    loading?: boolean;
    children: any;
    title?: string;
}
export const ProPageContainer = ({children, loading, title}: ProPageContainerProps) => {
    const {breadcrumbs} = useBreadcrumb();
    const {token} = theme.useToken();

    return (
        <PageContainer
            loading={loading}
            token={{
                paddingBlockPageContainerContent: 50,
                paddingInlinePageContainerContent: 50,
            }}
            title={title}
            ghost={false}
            header={{
                style: {backgroundColor: token.colorBgBase},
                breadcrumb: {
                    routes: [
                        {path: "/", breadcrumbName: "Home"},
                        ...breadcrumbs.map((b) => ({breadcrumbName: b.label, path: b.href!}))
                    ],
                    itemRender: (route) => {
                        return route.path
                            ? <Link to={route.path}>{route.breadcrumbName}</Link>
                            : <span>{route.breadcrumbName}</span>
                    }
                }
            }}
            childrenContentStyle={{padding: "1.25rem 1.5rem"}}
        >
            {children}
        </PageContainer>
    )
}
