/** @jsxImportSource @emotion/react */
import {Form, Input} from "@pankod/refine-antd";
import {UserOutlined} from "@ant-design/icons";
import {useToken} from "@ant-design/pro-components";

import {useAutocompleteCssFix} from "../useAutocompleteCssFix";

export const EmailField = (props: { noAutocomplete?: boolean }) => {
    const {token} = useToken();

    return (
        <Form.Item
            name="email"
            rules={[
                {required: true, message: "Email is required"},
                {type: "email", message: "Please enter a valid email address"}
            ]}
        >
            <Input
                type="email"
                css={useAutocompleteCssFix()}
                prefix={<UserOutlined style={{color: token.colorPrimary}}/>}
                placeholder="Email"
                autoComplete={props.noAutocomplete ? "new-password" : undefined}
            />
        </Form.Item>
    );
}
