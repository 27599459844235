import {useCallback, useMemo} from "react";
import {Button, Col, Form, Row} from "@pankod/refine-antd";
import {InboxOutlined} from "@ant-design/icons";

import {FormContainer} from "components/MultiStep/FormContainer";
import {Dragger} from "./Dragger";
import {SourceType} from "./SourceType";
import {BatchDataType} from "services/api/interface/Batch";
import {useFormWatch} from "hooks";
import {downloadFile} from "utils/downloadFile";

const fileDefinitionMapping: Partial<Record<BatchDataType, string>> = {
    [BatchDataType["Assets"]]: "/sampleFileDefinition/asset_import_template.csv",
    [BatchDataType["Fuel Usage"]]: "/sampleFileDefinition/fuel_import_template.csv",
    [BatchDataType["Electricity Usage"]]: "/sampleFileDefinition/electricity_import_template.csv",
};

export type BatchUploadStepProps = {
    labelSpan: number;
}
export const BatchUploadStep = ({labelSpan}: BatchUploadStepProps) => {
    const form = Form.useFormInstance();
    const batchType = useFormWatch<BatchDataType>("type", form);

    const fileDefinition = useMemo(() => {
        return fileDefinitionMapping[batchType];
    }, [batchType]);

    const onDownloadFile = useCallback(() => {
        if (fileDefinition != null) {
            downloadFile(fileDefinition);
        }
    }, [fileDefinition]);

    return (<>
        <SourceType/>
        <FormContainer>
            <Form.Item
                name="file"
                wrapperCol={{offset: labelSpan, span: 24 - labelSpan}}
            >
                <Dragger
                    multiple={false}
                    listType="picture"
                    accept=".xlsx,.csv,.txt"
                    maxCount={1}
                    beforeUpload={() => {
                        return false
                    }}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined/>
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                        Select a .csv, .xlsx or .txt file from your computer
                    </p>
                </Dragger>
            </Form.Item>
        </FormContainer>
        {fileDefinition != null && (
            <FormContainer>
                <Row>
                    <Col offset={labelSpan}>
                        <Button type="link" style={{paddingLeft: 0}} onClick={onDownloadFile}>
                            Click here to download a sample CSV
                        </Button>
                    </Col>
                </Row>
            </FormContainer>
        )}
    </>)
}
