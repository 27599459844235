import axios from "axios";
import * as yup from "yup";

export async function getRemoteYupSchema(token: string, type: string) {
    // token is needed here since this code might be invoked from within WebWorker
    // in which case we get different instance of axios created without any defaults set in the main thread
    const {data} = await axios.get(
        `${window.TBT.API_BASE_URL}/account/yupSchema/${type}`,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    );
    const handler = eval(`(${data})`);
    return handler(yup);
}
