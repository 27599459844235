import axios from "axios";
import {FC, ReactNode, useCallback, useEffect, useMemo, useState} from "react";
import {Button, Col, ColProps, Form, FormInstance, Skeleton, Space, Steps} from "@pankod/refine-antd";

import {
    Authenticated,
    useCheckError,
    useMutation,
    useNotification,
    useQuery,
    useRouterContext
} from "@pankod/refine-core";
import {useRouterSearchParams} from "../../pages/AccountSetup/useRouterSearchParams";
import {FormContainer} from "./FormContainer";
import {Alert} from "antd";

type MultiStepContext = {
    step: number;
    form: FormInstance;
}

export interface StepValidationResult {
    valid: boolean;
    error?: string;
    warning?: string;
}

export interface MultiStepProps<T> {
    authenticated?: boolean;

    steps: Array<{
        title?: ReactNode;
    }>;

    queryFn?: () => Promise<T>;

    initialValues?: Partial<T>;

    updateFn: (value: T) => Promise<any>;

    children: (ctx: MultiStepContext, renderError: (WrapperComponent?: FC<{ children: ReactNode }>) => ReactNode) => ReactNode | ReactNode[];

    labelSpan?: number;

    stepValidation?: (step: number, values: T) => Promise<void | undefined | StepValidationResult>;
}

const LABEL_SPAN = 8;

export function MultiStep<T = any>(props: MultiStepProps<T>) {
    const {
        authenticated = true,
        steps,
        queryFn = () => Promise.resolve({} as T),
        initialValues,
        updateFn,
        children,
        labelSpan = LABEL_SPAN,
        stepValidation,
    } = props;

    const searchParams = useRouterSearchParams();
    const {useHistory} = useRouterContext();
    const history = useHistory();
    const {mutate: checkError} = useCheckError();
    const notification = useNotification();
    const [form] = Form.useForm();
    const [stepError, setStepError] = useState<string>();
    const [stepWarning, setStepWarning] = useState<string>();
    const [isStepValidating, setIsStepValidating] = useState(false);

    const stepParam = searchParams.get("step");
    const step = Number(stepParam) - 1;
    const isLastStep = step === steps.length - 1;

    const setStep = useCallback((newStep: number, replace: boolean = false) => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set("step", String(newStep + 1));

        const newLocation = {search: newSearchParams.toString()};
        history.push(newLocation, {replace});
    }, [history, searchParams]);

    const ctx = useMemo<MultiStepContext>(() => {
        return {
            step,
            form
        }
    }, [step, form]);

    useEffect(() => {
        if (stepParam == null) {
            setStep(0, true);
        }
    }, [stepParam, setStep]);

    const {isFetching, isSuccess} = useQuery({
        queryKey: ["multistepQuery"],
        queryFn,
        onSuccess: (values) => form.setFieldsValue(values),
        retry: false,
        onError: checkError
    });

    const {mutate: update, isLoading: isUpdating} = useMutation({
        mutationFn: updateFn,
        retry: false,
        onError: (err: any) => {
            checkError(err);
            if (!axios.isAxiosError(err) || ![401, 403].includes(err.response?.status as any)) {
                notification.open?.({
                    message: err.message,
                    type: "error",
                });
            }
        }
    });

    const resetAlert = useCallback(() => {
        setStepError(undefined);
        setStepWarning(undefined);
    }, []);

    const validate = useCallback(async (): Promise<boolean> => {
        resetAlert();
        setIsStepValidating(true);

        try {
            await form.validateFields();
        }
        catch (e) {
            setIsStepValidating(false);
            return false;
        }

        if (stepValidation != null) {
            try {
                const validationResult = await stepValidation(step, form.getFieldsValue());
                if (validationResult != null && !validationResult.valid) {
                    setIsStepValidating(false);
                    if (validationResult.error) {
                        setStepError(validationResult.error);
                    } else {
                        setStepWarning(validationResult.warning);
                    }
                    return false;
                }
            }
            catch (e: any) {
                setIsStepValidating(false);
                setStepError(e.message);
                return false;
            }
        }

        setIsStepValidating(false);
        return true;
    }, [setStepError, setIsStepValidating, form, stepValidation, step, resetAlert]);

    const onNextClick = useCallback(async () => {
        if (await validate()) {
            setStep(step + 1);
        }
    }, [validate, step, setStep]);

    const onPrevClick = useCallback(() => {
        resetAlert();
        setStep(step - 1);
    }, [step, setStep, resetAlert]);

    const onFinishClick = useCallback(async () => {
        if (await validate()) {
            update(form.getFieldsValue());
        }
    }, [validate, update, form]);

    const onWarningAccept = useCallback(() => {
        setStepWarning(undefined);
        if (isLastStep) {
            update(form.getFieldsValue());
        } else {
            setStep(step + 1);
        }
    }, [step, setStep, update, form, isLastStep]);

    const labelCol: ColProps = useMemo(() => ({span: labelSpan}), [labelSpan]);

    let stepAlert: ReactNode | null = null;
    if (stepError != null) {
        stepAlert = <Alert
            type="error"
            message="File error"
            description={stepError}
            showIcon
            closable
            onClose={() => setStepError(undefined)}
        />;
    }
    else if (stepWarning != null) {
        stepAlert = <Alert
            type="warning"
            message="Batch Warning"
            description={stepWarning}
            showIcon
            closable
            action={
                <Button size="small" type="primary" onClick={onWarningAccept}>
                    Accept
                </Button>
            }
            onClose={() => setStepWarning(undefined)}
        />;
    }

    // if renderAlert() has been invoked from children function, prevent rendering of default error
    let shouldRenderDefaultAlert = true;
    const renderedChildren = children(ctx, (WrapperComponent) => {
        shouldRenderDefaultAlert = false;
        if (stepAlert == null) {
            return null;
        }

        if (WrapperComponent != null) {
            return <WrapperComponent>{stepAlert}</WrapperComponent>
        }
        else {
            return stepAlert;
        }
    });

    const formContent = (
        <>
            {shouldRenderDefaultAlert && stepAlert != null && (
                <FormContainer>
                    <Col offset={labelSpan}>
                        {stepAlert}
                    </Col>
                </FormContainer>
            )}
            {renderedChildren}
        </>
    );

    const renderContent = () => {
        return (
            <Skeleton
                loading={isFetching}
                style={{padding: "2rem"}}
                paragraph={{rows: 6, width: "20rem"}}
                active
            >
                {isSuccess && <div style={{padding: "0.5rem 0"}}>
                    <div style={{height: "4rem", display: "flex", alignItems: "center", padding: "0 8.75rem"}}>
                        <Steps current={step ?? 0} items={steps}/>
                    </div>
                    <div>
                        <Form
                            layout="horizontal"
                            form={form}
                            labelCol={labelCol}
                            initialValues={initialValues as any}
                            onFieldsChange={resetAlert}
                        >
                            {formContent}
                        </Form>
                        <FormContainer>
                            <Col offset={labelSpan}>
                                <Space>
                                    {!isLastStep && (
                                        <Button
                                            type="primary"
                                            onClick={onNextClick}
                                            loading={isStepValidating}
                                        >Next</Button>
                                    )}
                                    {isLastStep && (
                                        <Button
                                            type="primary"
                                            onClick={onFinishClick}
                                            loading={isUpdating || isStepValidating}
                                        >Finish</Button>
                                    )}
                                    {step > 0 && (
                                        <Button
                                            onClick={onPrevClick}
                                            disabled={isUpdating || isStepValidating}
                                        >Previous</Button>
                                    )}
                                </Space>
                            </Col>
                        </FormContainer>
                    </div>
                </div>}
            </Skeleton>
        )
    };

    if (authenticated) {
        return (
            <Authenticated>
                {renderContent()}
            </Authenticated>
        )
    }
    else {
        return renderContent();
    }
}

MultiStep.FormContainer = FormContainer;
