import {Tag} from "@pankod/refine-antd";

import {BatchStatus} from "services/api/interface/Batch";
import {getEnumLabel} from "../../utils/getEnumLabel";

const StatusColorMap = {
    [BatchStatus["Upload Pending"]]: "cyan",
    [BatchStatus.Uploaded]: "blue",
    [BatchStatus.Processing]: "gold",
    [BatchStatus["Accounts Processed"]]: "lime",
    [BatchStatus.Processed]: "green",
    [BatchStatus["Sync Pending"]]: "blue",
    [BatchStatus["Sync In Progress"]]: "gold",
    [BatchStatus["Sync Complete"]]: "green",
    [BatchStatus["Sync Incomplete"]]: "red",
    [BatchStatus.Error]: "red",
}

export type BatchStatusTagProps = {
    status?: BatchStatus
}
export const BatchStatusTag = ({status}: BatchStatusTagProps) => {
    return status == null ? null : <Tag color={StatusColorMap[status]}>{getEnumLabel(BatchStatus, status)}</Tag>
}
