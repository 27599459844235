import {getDefaultSortOrder} from "@pankod/refine-antd";
import {CrudFilters, CrudSorting, getDefaultFilter} from "@pankod/refine-core";
import {ProColumnType} from "@ant-design/pro-table/es/typing";
import {BaseEntity} from "services/api/interface/BaseEntity";
import {getEnumLabel} from "./getEnumLabel";
import {FilterDropdown} from "components/FilterDropdown";

type TableColumnArgs<T extends BaseEntity, FIn extends string | number | boolean> = ProColumnType<T> & {
    dataIndex: string | string[],
    title: string,
    filtersRefdata?: Record<string, FIn[]>,
    defaultSorter?: CrudSorting | undefined,
    defaultFilters?: CrudFilters | undefined,
    enumForFilters?: Record<string, FIn>,
};

export function tableColumn<T extends BaseEntity, FIn extends string | number | boolean>(args: TableColumnArgs<T, FIn>): ProColumnType<T> {
    const {
        dataIndex,
        filtersRefdata,
        defaultSorter,
        defaultFilters,
        enumForFilters,
        ...rest
    } = args;

    const key = Array.isArray(dataIndex) ? dataIndex.join(".") : dataIndex;
    const column: ProColumnType<T> = {
        dataIndex,
        key,
        sorter: true,
        showSorterTooltip: false,
        filteredValue: null,
        filters: false,
        ...rest
    };

    let transformFilter = (filter: FIn) => ({text: filter, value: filter});
    if (enumForFilters != null) {
        transformFilter = (filter) => ({
            text: getEnumLabel<Record<string, FIn>, any>(enumForFilters, filter),
            value: filter,
        })
    }

    if (filtersRefdata && filtersRefdata[key] != null && filtersRefdata[key]!.length > 0) {
        column.filters = filtersRefdata[key]!.map(transformFilter);
        column.filterSearch = filtersRefdata[key]!.length >= 9;
        column.filterDropdown = FilterDropdown;
    }
    if (defaultFilters) {
        column.filteredValue = getDefaultFilter(key, defaultFilters, "in") || null;
    }
    if (defaultSorter) {
        column.defaultSortOrder = getDefaultSortOrder(key, defaultSorter);
    }

    return column;
}
