import {Modal} from "antd";
import {Col, Form, Row} from "@pankod/refine-antd";

import {CommonModalProps} from "./interfaces";
import {AssetType} from "services/api/interface/Asset";
import {ElectricityFactorSet} from "./components/ElectricityFactorSet";
import {OtherFactorSet} from "./components/OtherFactorSet";
import {useModalSyncForm} from "./useModalSyncForm";
import {useEffect} from "react";

type AssetSyncModalProps = CommonModalProps & {
    type?: AssetType
}

export function AssetSyncModal(props: AssetSyncModalProps) {
    const {
        type,
        open,
        ...rest
    } = props;
    const {modalProps, formProps} = useModalSyncForm({...rest, open});

    useEffect(() => {
        formProps.form?.setFieldsValue({
            sfStationaryFactorSetId: "",
            sfMobileFactorSetId: ""
        });
    }, [formProps.form?.getFieldsValue]);

    return (
        <Modal {...modalProps}>
            <Form {...formProps}>
                <Row style={{marginTop: "1.5rem"}}>
                    <Col span={20} offset={2}>
                        <OtherFactorSet queryEnabled={open} type={type}/>
                        <ElectricityFactorSet queryEnabled={open}/>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}
