import {Form} from "@pankod/refine-antd";
import {useResourceSelect} from "hooks";
import {SelectX} from "../../SelectX";

type ElectricityFactorSetProps = {
    queryEnabled: boolean,
}

export function ElectricityFactorSet({queryEnabled}: ElectricityFactorSetProps) {
    const {selectProps: electricityEmSelectProps} = useResourceSelect({
        resource: "account/SFElectricityEmssnFctrSet",
        fieldNames: {label: "Name", value: "Id"},
        dropdownMatchSelectWidth: false,
        queryEnabled
    });

    return (
        <>
            <Form.Item
                name="sfElectricityFactorSetId"
                label="Salesforce Electricity Factor Set"
                tooltip="Select the default Electricity Factor Set to apply for the Stationary Assets"
                rules={[{required: true}]}
            >
                <SelectX {...electricityEmSelectProps}/>
            </Form.Item>
        </>
    )
}
