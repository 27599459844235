import {AuthProvider} from "@pankod/refine-core";
import axios, {AxiosError} from "axios";
import {API} from "./services/api";
import {Auth} from "./services/auth";

function getUser() {
    const token = Auth.jwt.get();
    if (token == null) {
        return null;
    }
    const userB64 = token.split(".")[1];
    if (userB64 == null) {
        return null;
    }

    return JSON.parse(atob(userB64));
}

export const useAuthProvider = (): AuthProvider => {
    return {
        register: async (signUpMessage: any): Promise<void | string> => {
            try {
                const token = await API.signup(signUpMessage);
                Auth.jwt.set(token);
                return "/account/setup";
            }
            catch (e: any) {
                return Promise.reject({name: e?.message ?? String(e)});
            }
        },
        login: async (loginMessage: any) => {
            console.log({loginMessage});
            if (loginMessage == null || typeof loginMessage !== "object") {
                return Promise.reject({name: "Missing login credentials"});
            }

            const {type, email, password} = loginMessage;

            const location = new URL(window.location.toString());
            const redirectTo = location.searchParams.get("to");
            switch (type) {
                case "sf":
                    API.loginSF(redirectTo);
                    // No need to resolve or reject this promise as we're redirecting to OAuth page
                    return new Promise<void>(() => false);
                case "email":
                    try {
                        Auth.jwt.set(await API.loginEmail(email, password));
                    }
                    catch (e: any) {
                        // Convert error to proper format for useLogin
                        return Promise.reject({name: e?.message ?? String(e)});
                    }

                    return {
                        success: true,
                        redirectTo
                    }
                default:
                    return Promise.reject({name: `Unsupported login type "${type}"`});
            }
        },
        logout: async () => {
            Auth.jwt.delete();
            return "/";
        },
        checkError: (error: Error | AxiosError) => {
            if (axios.isAxiosError(error) && error.response?.status === 401) {
                console.log(error.toJSON());
                return Promise.reject();
            }
            else {
                console.log(JSON.stringify(error, null, "  "));
                return Promise.resolve();
            }
        },
        checkAuth: async () => {
            // Check localStorage
            await Auth.checkAuthenticated();
        },
        getPermissions: () => Promise.resolve(),
        getUserIdentity: async () => {
            const user = getUser();
            if (user == null) {
                return null;
            }

            return {
                ...user,
                avatar: user.picture,
            };
        },
    };
};
