import {useNotification, useQueryClient, useRouterContext} from "@pankod/refine-core";
import {StepProps} from "@pankod/refine-antd";
import {ProCard} from "@ant-design/pro-components";

import {API} from "services/api";
import {AccountInfo} from "services/api/interface/AccountInfo";
import {MultiStep} from "components/MultiStep";
import {ProPageContainer} from "components/layout/ProPageContainer";

import {OrganizationInfo} from "./OrganizationInfo";
import {AccountPreferences} from "./AccountPreferences";

const STEPS: StepProps[] = [
    {title: "Organisation info"},
    {title: "Preferences"},
    {title: "Done"},
];

export const AccountSetupPage = () => {
    const queryClient = useQueryClient();
    const {useHistory} = useRouterContext();
    const history = useHistory();
    const {open} = useNotification();

    return (
        <ProPageContainer>
            <ProCard>
                <MultiStep
                    labelSpan={12}
                    authenticated
                    steps={STEPS}
                    queryFn={async (): Promise<Partial<AccountInfo>> => {
                        const data = await API.fetchOrganization();
                        return {
                            ...(data.preferences ?? {}),
                            orgName: data.name
                        };
                    }}
                    updateFn={async ({orgName, ...rest}: Partial<AccountInfo>) => {
                        // Strip orgName from payload
                        await API.updateOrganization({preferences: rest as any});
                        open?.({
                            message: "Account preferences successfully updated!",
                            type: "success",
                        });
                        await queryClient.resetQueries(["default", "account/SFEmissionFactorSet"]);
                        history.push("/");
                    }}
                >
                    {({step}) => (<>
                        {step === 0 && <OrganizationInfo/>}
                        {step === 1 && <AccountPreferences/>}
                        {step === 2 && <>
                            <OrganizationInfo readonly/>
                            <AccountPreferences readonly/>
                        </>}
                    </>)}
                </MultiStep>
            </ProCard>
        </ProPageContainer>
    )
};
