import {useQuery} from "@pankod/refine-core";

import {API} from "services/api";

type UseEmissionChartParams = {
    groupId?: string;
    assetId?: string;
}

export function useEmissionChart(params: UseEmissionChartParams) {
    const statsParams = {
        groupBy: "year" as const,
        ...params,
    };

    return useQuery({
        queryKey: ["accounts", "stats", JSON.stringify(statsParams)],
        queryFn: async () => {
            const data: Array<{ year: string, value: number, scope: string }> = [];

            const stats = await API.fetchStats(statsParams);
            stats.forEach((it) => {
                for (let i = 1; i <= 3; i++) {
                    data.push({
                        scope: `Scope ${i}`,
                        value: (it as any)[`scope${i}`] ?? 0,
                        year: it.group,
                    });
                }
            });

            return data;
        },
    });
}
