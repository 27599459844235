import {JSON2SheetOpts, utils, write} from "xlsx";

export type CsvRow = Record<string, string | number | boolean>;

export type ExportCsvParams = {
    data: CsvRow[];
    name?: string;
    headers?: Array<string | [string, string]>
}

export function exportCsv({data, name, headers}: ExportCsvParams) {
    const wb = utils.book_new();
    const opts: JSON2SheetOpts = {};
    if (headers != null) {
        opts.header = headers.map((it) => Array.isArray(it) ? it[0] : it)
    }
    const ws = utils.json_to_sheet(data, opts);
    if (headers != null) {
        utils.sheet_add_aoa(
            ws,
            [headers.map((it) => Array.isArray(it) ? it[1] : it)],
            {origin: "A1"}
        );
    }
    utils.book_append_sheet(wb, ws);
    const arrayBuffer = write(wb, {
        type: "array",
        bookType: "csv"
    });
    download(`${name}.csv`, "text/csv", arrayBuffer);
}

function download(filename: string, contentType: string, arrayBuffer: ArrayBuffer) {
    const link = document.createElement("a");
    const blob = new Blob([arrayBuffer], {type: contentType});
    const url = URL.createObjectURL(blob);

    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}
