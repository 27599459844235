import {Button, Col, Form, Input, Row} from "@pankod/refine-antd";

import {AgreeToTermsField, EmailField, PasswordField} from "./fields";
import {TabFormProps} from "./types";
import {useTerms} from "../../hooks/useTerms";
import {useCallback} from "react";

export const SignupForm = ({isLoading, onSubmit}: TabFormProps) => {
    const [form] = Form.useForm();
    const [agree, setAgree] = useTerms();

    const finishHandler = useCallback(async (values: any) => {
        setAgree(values.agree);
        await onSubmit(values);
    }, [setAgree, onSubmit]);


    return (
        <Form
            form={form}
            size="large"
            layout="vertical"
            disabled={isLoading}
            initialValues={{agree}}
            validateTrigger="onBlur"
            onFinish={finishHandler}
        >
            <Form.Item
                name="orgName"
                rules={[{required: true, message: "Organization Name is required"}]}
            >
                <Input placeholder="Organization Name"/>
            </Form.Item>
            <Form.Item
                name="businessNumber"
                rules={[{required: true, message: "ABN / NZBN is required"}]}
            >
                <Input placeholder="ABN / NZBN"/>
            </Form.Item>
            <Row gutter={6}>
                <Col span={12}>
                    <Form.Item
                        name="firstName"
                        rules={[{required: true, message: "First Name is required"}]}
                    >
                        <Input placeholder="First Name"/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="lastName"
                        rules={[{required: true, message: "Last Name is required"}]}
                    >
                        <Input placeholder="Last Name"/>
                    </Form.Item>
                </Col>
            </Row>
            <EmailField noAutocomplete/>
            <PasswordField noAutocomplete/>
            <AgreeToTermsField/>
            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    style={{width: "100%"}}
                    loading={isLoading}
                >
                    Create Account
                </Button>
            </Form.Item>
        </Form>
    )
}