let canvasBlock: HTMLCanvasElement;

export function getTextWidth(text: string, el: HTMLElement, defaultWidth: number): number {
    // re-use canvas object for better performance
    const canvas = canvasBlock || (canvasBlock = document.createElement("canvas"));
    const context = canvas.getContext("2d");
    let metrics = {width: defaultWidth};
    if (context) {
        const {fontWeight, fontSize, fontFamily} = window.getComputedStyle(el, null);
        context.font = `${fontWeight} ${fontSize} ${fontFamily}`;
        metrics = context.measureText(text);
    }
    return metrics.width;
}
