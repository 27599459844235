import {useQuery} from "@pankod/refine-core";

import {API} from "services/api";
import {enumToOptions} from "utils/enumToOptions";
import {ChartAccountCategories} from "services/api/interface/ChartAccount";

type UseScope3ChartParams = {
    groupId?: string;
    assetId?: string;
    batchId?: string;
    from?: string;
}

export function useScope3Chart(params: UseScope3ChartParams) {
    const statsParams = {
        groupBy: "category" as const,
        ...params
    };

    return useQuery({
        queryKey: ["accounts", "stats", JSON.stringify(statsParams)],
        queryFn: async () => {
            const stats = await API.fetchStats(statsParams);

            if (stats.length === 0) {
                return [];
            }

            return enumToOptions(ChartAccountCategories)
                .filter((it) => ![0, 1, 2].includes(it.value as number))
                .map(({label, value}) => {
                    const found = stats.find((it) => it.group === String(value));
                    return {
                        label,
                        value: found?.scope3 ?? 0
                    }
                });
        },
    });
}
