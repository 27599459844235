import {WorkerWrapper} from "./WorkerWrapper";
import {parseFile, ParseFileResult, ParseFilePayload} from "./parseFile";
import {checkBatchFile, CheckBatchFileResult, CheckBatchFilePayload} from "./checkBatchFile";

export const parseFileTaskManager = new WorkerWrapper<ParseFilePayload, ParseFileResult>(
    window.Worker != null ? new Worker(new URL("./parseFile/parseFileWorker.ts", import.meta.url)) : undefined,
    parseFile
);

export const checkBatchFileTaskManager = new WorkerWrapper<CheckBatchFilePayload, CheckBatchFileResult>(
    window.Worker != null ? new Worker(new URL("./checkBatchFile/checkBatchFileWorker.ts", import.meta.url)) : undefined,
    checkBatchFile
);
