import {BaseEntity} from "./BaseEntity";
import { EmissionFactor } from "./EmissionFactor";
import {Organization} from "./Organization";

export enum ChartAccountCategories {
    "Uncategorized" = 0,
    "Scope 1" = 1,
    "Scope 2" = 2,
    "Purchased Goods and Services" = 3,
    "Capital Goods" = 4,
    "Fuel and Energy-Related Activities" = 5,
    "Upstream Transportation and Distribution" = 6,
    "Waste Generated In Operations" = 7,
    "Business Travel" = 8,
    "Employee Commuting" = 9,
    "Upstream Leased Assets" = 10,
    "Downstream Transportation and Distribution" = 11,
    "Processing of Sold Products" = 12,
    "Use of Sold Products" = 13,
    "End-of-Life Treatment of Sold Products" = 14,
    "Downstream Leased Assets" = 15,
    "Franchises" = 16,
    "Investments" = 17
}

export enum ChartAccountType {
    Asset = "asset",
    Liability = "liability",
    Equity = "equity",
    Expense = "expense",
    Revenue = "revenue"
}

export interface ChartAccount extends BaseEntity {
    ignored: boolean;
    org_id: string;
    org: Organization;
    category?: ChartAccountCategories;
    acc_type?: ChartAccountType;
    acc_hash: string;
    acc_desc: string;
    group: string;
    ef_id?: number;
    ef?: EmissionFactor;
}
