import {useCallback, useState} from "react";
import {Button, Col, Row, Space} from "@pankod/refine-antd";
import {useShow} from "@pankod/refine-core";
import {ProCard} from "@ant-design/pro-components";

import {ProPageContainer} from "components/layout/ProPageContainer";
import {Descriptions} from "components/Descriptions";
import {AssetModal} from "components/asset/AssetModal";
import {SyncButton} from "components/syncButton";

import {Asset} from "services/api/interface/Asset";

export const AssetView = () => {
    const {queryResult} = useShow<Asset>();
    const {data, isFetching} = queryResult;
    const asset = data?.data;

    const [editModal, setEditModal] = useState(false);

    const onEditClick = useCallback(() => {
        setEditModal(true);
    }, []);

    const onEditClose = useCallback(() => {
        setEditModal(false);
    }, []);

    return (
        <ProPageContainer loading={isFetching && !editModal} title={asset?.name}>
            <ProCard>
                <Row
                    justify="center"
                    gutter={[8, 16]}
                    style={{padding: "0.5rem 0 1.25rem"}}
                >
                    <Col>
                        <Space>
                            <Button disabled={asset?.id == null} onClick={onEditClick}>Edit</Button>
                            <SyncButton asset={asset} onSync={queryResult.refetch}/>
                            <Button>Archive</Button>
                        </Space>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col style={{maxWidth: "56rem"}}>
                        <Descriptions
                            column={3}
                            items={[
                                {label: "Asset Type", value: asset?.type},
                                {label: "Ownership", value: asset?.ownership},
                                {label: "Identifier", value: asset?.identifier},
                                {label: "City", value: asset?.city},
                                {label: "State/Region", value: asset?.stateOrRegion},
                                {label: "Country", value: asset?.country},
                                {
                                    label: "Occupied Area",
                                    value: `${asset?.occupiedSpace} m2`,
                                    hidden: asset?.type !== "Stationary"
                                },
                                {label: "Reporting Group", value: asset?.reportingGroup?.name},
                            ]}
                        />
                    </Col>
                </Row>

                <AssetModal id={asset?.id} action={editModal ? "edit" : undefined} onClose={onEditClose}/>
            </ProCard>
        </ProPageContainer>
    );
};
