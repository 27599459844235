import axios from "axios";

const TOKEN_LOCALSTORAGE_ITEM_NAME = "token";

const checkInitialSearchParam = () => {
    const url = new URL(window.location.href);
    let shouldReplace = false;
    if (url.searchParams.has("token")) {
        shouldReplace = true;
        storeJWT(url.searchParams.get("token")!);
        url.searchParams.delete("token");
    }

    if (url.searchParams.has("setup")) {
        shouldReplace = true;
        if (url.searchParams.get("setup") === "true") {
            url.pathname = "/account/setup";
        }
        url.searchParams.delete("setup");
    }

    if (shouldReplace) {
        window.location.replace(url.toString());
    }

    return !shouldReplace;
};

const storeJWT = (token: string) => {
    localStorage.setItem(TOKEN_LOCALSTORAGE_ITEM_NAME, token);
}

const readJWT = () => localStorage.getItem(TOKEN_LOCALSTORAGE_ITEM_NAME);

const cleanJWT = () => localStorage.removeItem(TOKEN_LOCALSTORAGE_ITEM_NAME);

async function checkAuthenticated() {
    // Check localStorage
    const token = readJWT();
    if (token != null) {
        axios.defaults.headers.common = {
            Authorization: `Bearer ${token}`,
        };
    }
    else {
        delete axios.defaults.headers.common.Authorization;
        throw new Error("Not authenticated");
    }
}

export const Auth = {
    checkTokenSearchParam: checkInitialSearchParam,
    checkAuthenticated,
    jwt: {
        set: storeJWT,
        get: readJWT,
        delete: cleanJWT,
    }
};
