type TaskDefinition<Payload, Res> = {
    payload: Payload,
    resolve: (value: (Res | PromiseLike<Res>)) => void,
    reject: (reason: any) => void,
};

type MessageData<T> = {
    success: boolean;
    result?: T;
    error?: any;
}

export class WorkerWrapper<Payload = any, Res = any> {
    worker: Worker | undefined;
    handlerFn: Function;

    taskQueue: TaskDefinition<Payload, Res>[] = [];
    currentTask: TaskDefinition<Payload, Res> | undefined;

    constructor(worker: Worker | undefined, handlerFn: Function) {
        this.worker = worker;
        this.handlerFn = handlerFn;

        this.worker?.addEventListener("message", (e: MessageEvent<MessageData<Res>>) => {
            if (this.currentTask) {
                const {success, result, error} = e.data;
                if (success) {
                    this.currentTask.resolve(result!);
                }
                else {
                    this.currentTask.reject(error);
                }
                this.currentTask = undefined;
            }
            else {
                console.error("A message from worker received but not tasks was awaiting it", e.data);
            }

            this.checkQueue();
        });
    }

    async checkQueue() {
        if (this.currentTask != null) {
            // Let the task finish first
            return;
        }

        this.currentTask = this.taskQueue.shift();
        if (this.currentTask != null) {
            if (this.worker != null) {
                this.worker.postMessage({
                    ...this.currentTask.payload,
                    globals: {TBT: window.TBT}
                });
            }
            else {
                try {
                    this.currentTask.resolve(await this.handlerFn(this.currentTask.payload));
                }
                catch (e: any) {
                    this.currentTask.reject(e);
                }
                this.currentTask = undefined;
                this.checkQueue();
            }
        }
    }

    async startTask(payload: Payload) {
        return new Promise<Res>((resolve, reject) => {
            this.taskQueue.push({payload, resolve, reject});
            this.checkQueue();
        });
    }
}
