import {mixed, number, object, ObjectShape, string} from "yup";
import {capitalize, upperCase} from "lodash";
import {AssetOwnership, AssetOwnerships, AssetType, AssetTypes} from "../api/interface/Asset";

const basicAssetShape: ObjectShape = {
    name: string().required().label("Name"),
    type: mixed<AssetType>().required().transform(capitalize).oneOf(AssetTypes).label("Asset Type"),
    identifier: string().required().label("Identifier"),
    ownership: mixed<AssetOwnership>().required().transform(capitalize).oneOf(AssetOwnerships).label("Ownership"),
    reportingGroup: object().optional().shape({
        id: string().required().label("Reporting Group")
    }),
    city: string().optional().transform(capitalize).label("City"),
    stateOrRegion: string().optional().transform(upperCase).label("State/Region"),
    country: string().optional().length(2).uppercase().label("Country"),
    occupiedSpace: number().optional().positive().label("Occupied Area").typeError("${label} must be a number"),
};
export const assetSchema = object().shape(basicAssetShape);

export const assetSchemaBatch = object().shape({
    ...basicAssetShape,
    reportingGroup: string().optional().label("Reporting Group"),
});
