import {css} from "@emotion/react";
import {useToken} from "@ant-design/pro-components";
import {mixColors} from "./mixColors";

export const useAutocompleteCssFix = () => {
    const {token} = useToken();

    const disabledColor = mixColors(
        token.colorBgLayout,
        token.colorBgContainerDisabled
    );

    return css`
      & input:-webkit-autofill,
      & input:-webkit-autofill:hover,
      & input:-webkit-autofill:focus {
        -webkit-text-fill-color: ${token.colorText} !important;
        -webkit-box-shadow: 0 0 0 1000px ${token.colorBgContainer} inset !important;

        &:disabled {
          -webkit-text-fill-color: ${token.colorTextDisabled} !important;
          -webkit-box-shadow: 0 0 0 1000px ${disabledColor} inset !important;
        }
      }
    `;
}
