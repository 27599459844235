import {useCallback, useEffect, useMemo} from "react";
import {Form, ModalProps} from "@pankod/refine-antd";
import {FormProps} from "@ant-design/pro-components";

import {CommonModalProps} from "./interfaces";

export function useModalSyncForm(props: CommonModalProps) {
    const {
        onCancel,
        onFinish,
        open,
        loading,
    } = props;
    const [form] = Form.useForm();

    useEffect(() => {
        if (!open) {
            form.resetFields();
        }
    }, [open, form.resetFields]);

    const okHandler = useCallback(() => {
        form.submit();
    }, [form.submit]);

    return useMemo<{modalProps: ModalProps, formProps: FormProps}>(() => {
        return {
            modalProps: {
                title: "Sync to Salesforce",
                width: "40rem",
                bodyStyle: {paddingTop: "1rem"},
                okText: "Sync",
                open,
                onCancel,
                onOk: okHandler,
                okButtonProps: {loading},
                cancelButtonProps: {disabled: loading},
            },
            formProps: {
                form,
                labelAlign: "left",
                labelCol: {span: 12},
                layout: "horizontal",
                onFinish,
            }
        }
    }, [open, okHandler, loading, form, onFinish]);
}
