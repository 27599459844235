export enum Countries {
    "Australia" = "AU",
    "Hong Kong" = "HK",
    "Malaysia" = "MY",
    "New Zealand" = "NZ",
    "Singapore" = "SG",
    "United Kingdom" = "UK",
    "United States of America" = "US"
}

export type OrganizationInfoType = {
    orgName?: string;
    industry: number;
    country: Countries;
}

export type AccountPreferencesType = {
    emissionFactor?: EmissionFactor;
    carbonAccountingApproach?: CarbonAccountingApproach;
    notificationPreference?: NotificationPreference;
    notificationRecipient?: string;
}

export type AccountInfo = OrganizationInfoType & AccountPreferencesType;

export const Currencies = ["AUD", "HKD", "MYR", "NZD", "SGD", "EUR", "GBP", "USD"] as const;
export type Currency = typeof Currencies[number];

/*
    IMPORTANT!!! Don't change the order of items in Enums for now as it might mess up on existing data
 */


export enum Industries {
    "Accommodation and Food Services",
    "Administrative and Support Services",
    "Agriculture, Forestry and Fishing",
    "Arts and Recreation Services",
    "Construction",
    "Education and Training",
    "Electricity, Gas, Water and Waste Services",
    "Financial and Insurance Services",
    "Health Care and Social Assistance",
    "Information Media and Telecommunications",
    "Manufacturing",
    "Mining",
    "Personal Services",
    "Professional, Scientific and Technical Services",
    "Public Administration and Safety",
    "Rental, Hiring and Real Estate Services",
    "Retail Trade",
    "Thematic Reports",
    "Transport, Postal and Warehousing",
    "Wholesale Trade",
}

export enum EmissionFactor {
    "Conservative",
    "Aggressive",
}

export enum CarbonAccountingApproach {
    "Operational",
    "Equity",
}

export enum NotificationPreference {
    "In App Only",
    "Email Only",
    "Both",
}
