import {useQuery} from "@pankod/refine-core";
import {DateTime} from "luxon";

import {API} from "services/api";

type ScopesChartParams = {
    groupId?: string;
    assetId?: string;
}

export function useScopesChart(params: ScopesChartParams) {
    return useQuery({
        queryKey: ["accounts", "stats", JSON.stringify(params)],
        queryFn: async () => {
            const [stats] = await API.fetchStats({
                from: DateTime.now().startOf("year").toISODate(),
                ...params
            });

            const data: Array<{ value: number, scope: string }> = [];

            for (let i = 1; i <= 3; i++) {
                const value = (stats as any)[`scope${i}`];
                if (value != null) {
                    data.push({
                        scope: `Scope ${i}`,
                        value: value ?? 0,
                    });
                }
            }

            return data;
        },
    });
}
