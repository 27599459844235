import {ReactNode} from "react";
import {
    Col,
    Row,
} from "@pankod/refine-antd";

import {DescriptionItem} from "./DescriptionItem";

type DescriptionsProps = {
    items: Array<{ label: string, value?: ReactNode, hidden?: boolean }>
    column?: number;
    labelSpan?: number;
}

export function Descriptions({items, column = 1, labelSpan = 10}: DescriptionsProps) {
    return (
        <Row gutter={[24, 16]}>
            {items.map((it) => !it.hidden && (
                <Col span={Math.floor(24 / column)} key={it.label}>
                    <DescriptionItem
                        labelSpan={labelSpan}
                        label={it.label}
                        value={it.value}
                        key={it.label}
                    />
                </Col>
            ))}
        </Row>
    );
}
