/** @jsxImportSource @emotion/react */
import {Form, Input} from "@pankod/refine-antd";
import {LockOutlined} from "@ant-design/icons";
import {useToken} from "@ant-design/pro-components";

import {useAutocompleteCssFix} from "../useAutocompleteCssFix";

export const PasswordField = (props: { noAutocomplete?: boolean }) => {
    const {token} = useToken();

    return (
        <Form.Item
            name="password"
            rules={[{required: true, message: "Password is required"}]}
        >
            <Input.Password
                css={useAutocompleteCssFix()}
                prefix={<LockOutlined style={{color: token.colorPrimary}}/>}
                placeholder="Password"
                autoComplete={props.noAutocomplete ? "new-password" : undefined}
            />
        </Form.Item>
    );
}
