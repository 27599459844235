import {ReactNode} from "react";
import {Col, Row, Typography} from "@pankod/refine-antd";

export type DescriptionItemProps = {
    label: string;
    value?: ReactNode;
    labelSpan?: number;
}
export const DescriptionItem = ({label, value, labelSpan}: DescriptionItemProps) => {
    return (
        <Row>
            <Col span={labelSpan}>
                <Typography.Text
                    type="secondary"
                    style={{width: "100%"}}
                    ellipsis={{
                        suffix: ":",
                        tooltip: label,
                    }}
                >{label}</Typography.Text>
            </Col>
            <Col>
                <Typography.Text>{value}</Typography.Text>
            </Col>
        </Row>
    )
}
