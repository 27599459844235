import {Avatar, Dropdown, Space, Spin, theme} from "@pankod/refine-antd";
import {LogoutOutlined, UserOutlined} from "@ant-design/icons";
import {useGetIdentity, useLogout} from "@pankod/refine-core";

export const RightContent = () => {
    const {token} = theme.useToken();
    const {mutate: logout, isLoading: isLoggingOut} = useLogout();
    const {data: user} = useGetIdentity();

    return (
        <Spin spinning={isLoggingOut}>
            <Dropdown menu={{
                items: [{
                    key: "logout",
                    label: "Logout",
                    icon: <LogoutOutlined/>,
                    onClick: () => logout(),
                }]
            }}>
                <Space style={{cursor: "pointer"}}>
                    {user?.avatar != null && <Avatar src={user?.avatar}/>}
                    {user?.avatar == null && <Avatar icon={<UserOutlined/>}/>}
                    {user?.name && <span style={{color: token.colorTextSecondary}}>{user?.name}</span>}
                </Space>
            </Dropdown>
        </Spin>
    )
};
