import {Form, Input, Select} from "@pankod/refine-antd";

import {CarbonAccountingApproach, EmissionFactor, NotificationPreference} from "services/api/interface/AccountInfo";
import {FormContainer} from "components/MultiStep/FormContainer";
import {enumToOptions} from "utils/enumToOptions";
import {StepProps} from "./interface";
import {useIfNotReadonly} from "./useIfNotReadonly";

const emissionFactorOptions = enumToOptions(EmissionFactor);
const carbonAccountingApproachOptions = enumToOptions(CarbonAccountingApproach);
const notificationPreferenceOptions = enumToOptions(NotificationPreference);

export const AccountPreferences = ({readonly = false}: StepProps) => {
    const ifNotReadonly = useIfNotReadonly(readonly);

    return (
        <FormContainer>
            <Form.Item
                name="emissionFactor"
                label="Emission Factor Selection"
                tooltip={ifNotReadonly("In the scenario of multiple matches, a Conservative emissions factor selection would select a mid-range factor, while an Aggressive one would always select the highest.")}
                labelAlign="left"
            >
                <Select
                    options={emissionFactorOptions}
                    disabled={readonly}
                />
            </Form.Item>
            <Form.Item
                name="carbonAccountingApproach"
                label="Carbon Accounting Approach"
                tooltip={ifNotReadonly("Based on the GHG Protocol accounting approach for carbon accounting.")}
                labelAlign="left"
            >
                <Select
                    options={carbonAccountingApproachOptions}
                    disabled={readonly}
                />
            </Form.Item>
            <Form.Item
                name="notificationPreference"
                label="Notification Preference"
                tooltip={ifNotReadonly("Notifications are generated on processing completion and other background processes.")}
                labelAlign="left"
            >
                <Select
                    options={notificationPreferenceOptions}
                    disabled={readonly}
                />
            </Form.Item>
            <Form.Item
                name="notificationRecipient"
                label="Notification Recipient"
                tooltip={ifNotReadonly("A mailbox where the processing result could be sent.")}
                rules={ifNotReadonly([{type: "email"}])}
                labelAlign="left"
            >
                <Input disabled={readonly}/>
            </Form.Item>
        </FormContainer>
    );
};
