import React, {useCallback, useEffect} from "react";
import {AntdLayout, Button, Col, Row, Space, Typography} from "@pankod/refine-antd";
import {Tabs} from "antd";
import {useLogin, useRegister, useRouterContext} from "@pankod/refine-core";
import {useDocumentTitle} from "@ant-design/pro-components";
import {useLocation} from "@pankod/refine-react-router-v6";

import LoginBG from "assets/login_background.svg";
import {APP_TITLE} from "../../constants";
import {LoginForm} from "./LoginForm";
import {SignupForm} from "./SignupForm";

import "./login.scss";

const {Text, Title} = Typography;

export const Login: React.FC = () => {
    const {mutate: login, isLoading: isSigningIn} = useLogin();
    const {mutate: signup, isLoading: isSigningUp} = useRegister()
    useDocumentTitle({} as any, `Login - ${APP_TITLE}`);
    const {useHistory} = useRouterContext();
    const {replace} = useHistory();
    const {pathname, search} = useLocation();

    useEffect(() => {
        if (!/^\/(?:login|signup)$/.test(pathname)) {
            replace("/login");
        }
    }, [replace, pathname]);

    const setActiveTab = useCallback((tabKey: string) => {
        replace(`${tabKey}${search}`);
    }, [replace, search]);

    return (
        <AntdLayout
            style={{
                backgroundImage: `url('${LoginBG}')`,
                backgroundSize: "cover",
                minHeight: "100vh",
            }}
        >
            <div className="tbt-login-page__content">
                <div className="tbt-login-page__login-header">
                    <Row>
                        <Col>
                            <Space>
                                <img
                                    alt="Smart DataHub logo"
                                    width={123}
                                    src="./logo.svg"
                                />
                                <Title
                                    level={2}
                                    className="tbt-login-page__login-header-title"
                                    style={{margin: '.5rem 0 0'}}
                                >Smart DataHub</Title>
                            </Space>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '.75rem'}}>
                        <Col>
                            <Text type="secondary">A managed service for environmentally conscious companies</Text>
                        </Col>
                    </Row>
                </div>
                <div className="tbt-login-page__login-form">
                    <Tabs
                        activeKey={pathname}
                        onChange={setActiveTab}
                        items={[
                            {
                                key: "/login",
                                label: "Login",
                                children: <LoginForm
                                    onSubmit={login}
                                    isLoading={isSigningIn}
                                />
                            },
                            {
                                key: "/signup",
                                label: "Signup",
                                children: <SignupForm
                                    onSubmit={signup}
                                    isLoading={isSigningUp}
                                />
                            }
                        ]}
                    />
                    <Space align="center">
                        <Text>Quick Sign-{pathname === "/signup" ? "up" : "in"}:</Text>
                        <Button
                            type="primary"
                            size="large"
                            block
                            onClick={() => login({type: "sf"})}
                            disabled={isSigningIn || isSigningUp}
                        >
                            With Salesforce
                        </Button>
                    </Space>
                </div>
            </div>
            <AntdLayout.Footer style={{display: "flex", justifyContent: "center", background: "none"}}>
                <Text type="secondary">Copyright ©2023 Powered by The Big Toolbox</Text>
            </AntdLayout.Footer>
        </AntdLayout>
    );
};
