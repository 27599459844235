import {Form} from "@pankod/refine-antd";
import {useResourceSelect} from "hooks";
import {AssetType} from "services/api/interface/Asset";
import {SelectX} from "../../SelectX";

type OtherFactorSetProps = {
    queryEnabled: boolean,
    type?: AssetType
}

export function OtherFactorSet({queryEnabled, type}: OtherFactorSetProps) {
    const {selectProps: otherEmSelectProps} = useResourceSelect({
        resource: "account/SFOtherEmssnFctrSet",
        fieldNames: {label: "Name", value: "Id"},
        dropdownMatchSelectWidth: false,
        queryEnabled
    });

    return (
        <>
            <Form.Item
                name="sfStationaryFactorSetId"
                label="Salesforce Stationary Factor Set"
                tooltip="Select the default Fuel Factor Set to apply for the Stationary Assets"
                rules={[{required: type === "Stationary"}]}
                hidden={type === "Mobile"}
            >
                <SelectX {...otherEmSelectProps}/>
            </Form.Item>

            <Form.Item
                name="sfMobileFactorSetId"
                label="Salesforce Mobile Factor Set"
                tooltip="Select the default Fuel Factor Set to apply for the Mobile Assets"
                rules={[{required: type === "Mobile"}]}
                hidden={type === "Stationary"}
            >
                <SelectX {...otherEmSelectProps}/>
            </Form.Item>
        </>
    )
}
