import {Select, SelectProps} from "@pankod/refine-antd";
import {useCallback, useMemo} from "react";

export type SelectXProps<Val extends {}, Opt extends {} = Val> = SelectProps<Val, Opt> & {
    recordIsValue?: boolean;
}

export function SelectX<Val extends {}, Opt extends {} = Val>(props: SelectXProps<Val, Opt>) {
    const {
        recordIsValue,
        loading,
        fieldNames,
        options,
        placeholder: placeholderFromProps,
        value: valueFromProps,
        onChange: onChangeFromProps,
        ...rest
    } = props;

    const {value: valueKey = "id", label: labelKey = "name"} = fieldNames ?? {};

    const value = useMemo(() => {
        if (loading) {
            return undefined;
        }

        if (!recordIsValue || valueFromProps == null) {
            return valueFromProps;
        }
        else {
            return {
                value: (valueFromProps as any)[valueKey],
                label: (valueFromProps as any)[labelKey]
            };
        }
    }, [recordIsValue, valueFromProps, valueKey, loading]);

    const onChange = useCallback((value: Val, opt: any) => {
        if (recordIsValue) {
            return onChangeFromProps?.(opt, opt);
        }
        else {
            return onChangeFromProps?.(value, opt);
        }
    }, [recordIsValue, options, valueKey, onChangeFromProps]);

    return (
        <Select<Val, Opt>
            {...rest}
            loading={loading}
            fieldNames={fieldNames}
            options={options}
            placeholder={loading ? "Loading..." : placeholderFromProps}
            value={value as any}
            labelInValue={recordIsValue}
            onChange={onChange}
        />
    );
}
