import {createRoot} from "react-dom/client";

import reportWebVitals from "./reportWebVitals";
import App from "./App";
import {Auth} from "./services/auth";
import {initSentry} from "./services/sentry";

initSentry();

if (Auth.checkTokenSearchParam()) {
    const container = document.getElementById("root") as HTMLElement;
    const root = createRoot(container);

    root.render(
        // todo: Issue with dropdown in antd with React.StrictMode
        // <React.StrictMode>
        <App/>
        // </React.StrictMode>
    );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
}
