import {Form, Input, Select} from "@pankod/refine-antd"
import {useMemo} from "react";

import {Countries, Currencies, Industries} from "services/api/interface/AccountInfo";
import {FormContainer} from "components/MultiStep/FormContainer";
import {enumToOptions} from "utils/enumToOptions";
import {arrayToOptions} from "utils/arrayToOptions";
import {StepProps} from "./interface";
import {useIfNotReadonly} from "./useIfNotReadonly";

export const OrganizationInfo = ({readonly = false}: StepProps) => {
    const industryOptions = useMemo(() => enumToOptions(Industries), []);
    const ifNotReadonly = useIfNotReadonly(readonly);

    return (
        <FormContainer>
            <Form.Item
                name="orgName"
                label="Organisation Name"
                labelAlign="left"
            >
                <Input disabled/>
            </Form.Item>
            <Form.Item
                name="industry"
                label="Industry"
                tooltip={ifNotReadonly("This is the industry or sector code of your organisation. It assists in fine-tuning our smarts for your organisation.")}
                rules={ifNotReadonly([{required: true}])}
                labelAlign="left"
            >
                <Select
                    options={industryOptions}
                    dropdownMatchSelectWidth={320}
                    disabled={readonly}
                />
            </Form.Item>
            <Form.Item
                name="country"
                label="Country"
                labelAlign="left"
            >
                <Select options={enumToOptions(Countries)} disabled={readonly}/>
            </Form.Item>
            <Form.Item
                name="currency"
                label="Corporate Currency"
                labelAlign="left"
                rules={ifNotReadonly([{required: true}])}
            >
                <Select options={arrayToOptions(Currencies)} disabled={readonly}/>
            </Form.Item>
        </FormContainer>
    );
}
