import {Form, Select} from "@pankod/refine-antd";

import {enumToOptions} from "utils/enumToOptions";
import {BatchSourceType} from "services/api/interface/Batch";
import {FormContainer} from "components/MultiStep/FormContainer";

export const SourceType = () => {
    return (
        <FormContainer>
            <Form.Item
                name="source"
                label="Source Type"
                tooltip="Please specify the source of this data. If your system is not listed, choose the 'File Upload' option."
                labelAlign="right"
            >
                <Select
                    options={enumToOptions(BatchSourceType)}
                    disabled
                />
            </Form.Item>
        </FormContainer>
    );
}
