import {Column, ColumnConfig} from "@ant-design/plots";

const chartOptions: Partial<ColumnConfig> = {
    xAxis: {
        label: {
            autoRotate: true,
            autoHide: false,
            autoEllipsis: true
        }
    },
    columnStyle: {
        fillOpacity: 1
    },
    label: {
        formatter: (it) => it.value.toFixed(2),
    },
    tooltip: {
        formatter: ({value, label}) => ({
            value: value.toFixed(2),
            name: label,
        }),
        showTitle: false,
    },
}

export function Scope3Chart({data}: { data: Array<any> }) {
    return (
        <Column
            {...chartOptions}
            xField="label"
            yField="value"
            data={data}
        />
    );
}
