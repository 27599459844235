import {Tooltip} from "@pankod/refine-antd";
import {QuestionCircleOutlined} from "@ant-design/icons";

export const LabelWithHint = ({title, hint}: { title: string, hint?: string }) => {
    return (
        <>
            {title}
            {hint && (
                <Tooltip title={hint}>
                    <QuestionCircleOutlined style={{
                        marginLeft: "0.25rem",
                        cursor: "pointer",
                    }}/>
                </Tooltip>
            )}
        </>
    );
};
