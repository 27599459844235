import {useCallback, useMemo} from "react";
import {Modal} from "antd";
import {Checkbox, Col, Form, Row, Select, Skeleton, useForm} from "@pankod/refine-antd";
import {DeepPartial} from "@ant-design/pro-components";

import {ChartAccount, ChartAccountCategories, ChartAccountType} from "services/api/interface/ChartAccount";
import {enumToOptions} from "utils/enumToOptions";
import {LookUp} from "../LookUp";

const chartAccountTypeOptions = enumToOptions(ChartAccountType);
const ChartAccountCategoriesOptions = enumToOptions(ChartAccountCategories);

export type COAModalProps = {
    id?: string;
    onClose: (result: "cancel" | "ok") => any;
}

export function COAModal({id, onClose}: COAModalProps) {
    const {form, formProps, formLoading, onFinish, queryResult, mutationResult} = useForm<ChartAccount>({
        resource: "account/coa",
        action: "edit",
        id,
        redirect: false,
        queryOptions: {
            staleTime: 60 * 5 * 1000,
        }
    });

    const open = useMemo(() => id != null, [id]);
    const desc = queryResult?.data?.data.acc_desc;

    const modalTitle = useMemo(() => desc ?? "Chart of Account", [desc]);

    const okHandler = useCallback(async () => {
        const values = form.getFieldsValue() as ChartAccount;
        await onFinish(values);
        onClose("ok");
    }, [onClose, onFinish, form.getFieldsValue]);

    const cancelHandler = useCallback(() => {
        onClose("cancel");
    }, [onClose]);

    const renderModalContent = () => (
        <Form<DeepPartial<ChartAccount>>
            {...formProps}
            labelAlign="left"
            layout="vertical"
            disabled={mutationResult.isLoading}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item name="acc_type" label="Type">
                        <Select options={chartAccountTypeOptions}/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="ignored" label="Ignore" valuePropName="checked">
                        <Checkbox />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item name="category" label="Category">
                        <Select options={ChartAccountCategoriesOptions} dropdownMatchSelectWidth={false}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item name="ef" label="Emission Factor">
                        <LookUp
                            recordIsValue
                            sort={[{field: "description", order: "asc"}]}
                            resource="ef/set/default/item"
                            fieldNames={{label: "description", value: "id"}}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );

    return (
        <Modal
            title={modalTitle}
            open={open}
            onCancel={cancelHandler}
            onOk={okHandler}
            width="50vw"
            bodyStyle={{paddingTop: "1rem"}}
            okButtonProps={{
                disabled: formLoading,
                loading: mutationResult.isLoading
            }}
            cancelButtonProps={{disabled: mutationResult.isLoading}}
        >
            <Skeleton
                loading={queryResult?.isFetching}
                active
                paragraph={{rows: 6}}
                children={renderModalContent()}
            />
        </Modal>
    );
}
