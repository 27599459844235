import {DependencyList, EffectCallback, useEffect, useRef} from "react";

export const useAfterMountEffect = (effect: EffectCallback, deps: DependencyList) => {
    const ref = useRef(false);
    useEffect(() => {
        if (!ref.current) {
            ref.current = true;
        }
        else {
            return effect();
        }
    }, deps);
}
