import {ReactNode, useCallback, useMemo} from "react";
import {Empty, Skeleton, Space, Typography} from "@pankod/refine-antd";
import {ProCard} from "@ant-design/pro-components";

import {ExportButton} from "./ExportButton";

export type ChartExportOptions = {
    headers?: Array<string | [string, string]>;
    name?: string;
};

export type ChartContainerProps = {
    children: ReactNode | ReactNode[];
    title: string;
    data?: any[];
    extra?: ReactNode;
    loading?: boolean;
    noDataText?: string;
    exportOptions?: ChartExportOptions;
}

export function ChartContainer(props: ChartContainerProps) {
    const {
        children,
        extra,
        data,
        title,
        loading = false,
        noDataText = "No data to display. Process your first batch to populate chart.",
        exportOptions,
    } = props;

    const noData = useMemo(() => {
        return !loading && (data == null || data.length === 0);
    }, [data, loading]);

    const onData = useCallback(() => {
        return {
            data: data ?? [],
            name: exportOptions?.name ?? "export",
            headers: exportOptions?.headers,
        }
    }, [data, exportOptions?.name, exportOptions?.headers]);

    return (
        <ProCard
            title={
                <Space>
                    <Typography.Text>{title}</Typography.Text>
                    <ExportButton disabled={loading || noData} onData={onData}/>
                </Space>
            }
            extra={extra}
        >
            <Skeleton
                loading={loading}
                active
                paragraph={{rows: 6}}
            >
                {!noData && children}
                {noData && (
                    <Empty
                        style={{paddingTop: "5rem", paddingBottom: "5rem"}}
                        description={noDataText}
                    />
                )}
            </Skeleton>
        </ProCard>
    )
}
