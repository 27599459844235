import {useCallback, useMemo, useState} from "react";
import {Button, ButtonProps} from "@pankod/refine-antd";
import {useGetIdentity, useNotification} from "@pankod/refine-core";

import {Asset} from "services/api/interface/Asset";
import {Batch, BatchDataType, BatchStatus, BatchTarget} from "services/api/interface/Batch";
import {AssetSyncModal} from "./AssetSyncModal";
import {API} from "services/api";
import {LedgerSyncModal} from "./LedgerSyncModal";
import {CommonModalProps} from "./interfaces";
import {FuelSyncModal} from "./FuelSyncModal";
import {ElectricitySyncModal} from "./ElectricitySyncModal";

export type SyncButtonProps = {
    asset?: Asset,
    batch?: Batch,
    onSync?: () => any,
    type?: ButtonProps["type"]
};

export function SyncButton(props: SyncButtonProps) {
    const {
        asset,
        batch,
        onSync,
        type = "default"
    } = props;
    const {data: user} = useGetIdentity();
    const notification = useNotification();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const onCancel = useCallback(() => {
        setOpen(false);
    }, []);

    const onOpen = useCallback(() => {
        setOpen(true);
    }, []);

    const onFinish = useCallback(async (values: any) => {
        setLoading(true);
        try {
            if (asset != null) {
                await API.syncAsset(asset.id, values);
            }
            else if (batch != null) {
                await API.syncBatch(batch.id, values);
            }
            onSync?.();
            setOpen(false);
        }
        catch (e: any) {
            notification.open?.({
                message: e.message,
                type: "error",
            });
        } finally {
            setLoading(false);
        }
    }, [asset, batch, notification, onSync]);

    const enabled = useMemo(() => {
        if (asset != null) {
            return user?.sfUser === true;
        }
        if (batch != null) {
            return batch.target === BatchTarget.Salesforce
                && batch.status === BatchStatus.Processed;
        }

        return false;
    }, [asset, batch, user?.sfUser]);

    const renderModal = () => {
        const modalProps: CommonModalProps = {
            open,
            onFinish,
            onCancel,
            loading
        }

        if (asset != null) {
            return <AssetSyncModal {...modalProps} type={asset.type}/>;
        }
        else if (batch != null) {
            switch (batch.type) {
                case BatchDataType["General Ledger"]:
                case BatchDataType["Purchase Ledger"]:
                    return <LedgerSyncModal {...modalProps}/>;

                case BatchDataType["Assets"]:
                    return <AssetSyncModal {...modalProps}/>;

                case BatchDataType["Fuel Usage"]:
                    return <FuelSyncModal {...modalProps}/>;

                case BatchDataType["Electricity Usage"]:
                    return <ElectricitySyncModal {...modalProps}/>;
            }
        }
        return null;
    };

    return (
        <>
            <Button
                disabled={!enabled}
                type={type}
                onClick={onOpen}
            >
                Sync
            </Button>

            {renderModal()}
        </>
    );
}
