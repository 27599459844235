import {useQuery} from "@pankod/refine-core";

import {API} from "../services/api";

export function useStats() {
    return useQuery({
        queryKey: ["account", "stats"],
        queryFn: async () => {
            const data = await API.fetchOverallStats();
            return {data};
        },
    });
}
