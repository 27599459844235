import {
    Batch,
    BatchesWithPeriod,
    BatchMonthlyPeriod,
    BatchPeriodType,
    BatchQuarterlyPeriod
} from "services/api/interface/Batch";
import {getEnumLabel} from "../getEnumLabel";

export function resolvePeriod(batch?: Batch) {
    if (batch == null || !BatchesWithPeriod.includes(batch.type)) {
        return null;
    }

    let res: string = "";
    if (batch.reporting_period_type === BatchPeriodType["Monthly"]) {
        res = getEnumLabel(BatchMonthlyPeriod, batch.reporting_period as BatchMonthlyPeriod)!;
    }
    else if (batch.reporting_period_type === BatchPeriodType["Quarterly"]) {
        res = getEnumLabel(BatchQuarterlyPeriod, batch.reporting_period as BatchQuarterlyPeriod)!;
    }

    return `${res} ${String(batch.reporting_year)}`;
}
