import {ProColumns, ProTable} from "@ant-design/pro-components";
import {Button, useTable} from "@pankod/refine-antd";
import {EditOutlined, PlusOutlined} from "@ant-design/icons";
import {useApiUrl, useCustom, useNavigation, useResource} from "@pankod/refine-core";

import {ProPageContainer} from "components/layout/ProPageContainer";
import {AssetModal} from "components/asset/AssetModal";
import {Asset} from "services/api/interface/Asset";
import {useCallback, useMemo, useState} from "react";

import "./asset-list.scss";
import {tableColumn} from "utils/tableColumn";

export const AssetList = () => {
    const {tableProps, tableQueryResult, sorter: defaultSorter, filters: defaultFilters} = useTable<Asset>({
        syncWithLocation: true
    });

    const [modalAction, setModalAction] = useState<"create" | "edit">();
    const [assetId, setAssetId] = useState<string>();
    const {show} = useNavigation();
    const {resource} = useResource();

    const apiUrl = useApiUrl();
    const {data: filtersData, refetch: refetchFilters} = useCustom({
        url: `${apiUrl}/account/asset/_refdata`,
        method: "get",
        config: {
            query: {
                field: ["name", "type", "ownership", "country"],
            },
        },
    });

    const defaultColumnProps = useMemo(() => {
        return {
            filtersRefdata: filtersData?.data ?? {},
            defaultSorter: defaultSorter,
            defaultFilters: defaultFilters
        };
    }, [filtersData, defaultSorter, defaultFilters]);

    const columns: ProColumns<Asset>[] = [
        tableColumn({
            dataIndex: "name",
            title: "Name",
            ...defaultColumnProps
        }),
        tableColumn({
            dataIndex: "type",
            title: "Type",
            ...defaultColumnProps
        }),
        tableColumn({
            dataIndex: "ownership",
            title: "Ownership",
            ...defaultColumnProps
        }),
        tableColumn({
            dataIndex: "country",
            title: "Country",
            ...defaultColumnProps
        }),
        {
            title: "Action",
            render: (_, asset) => (
                <Button
                    type="link"
                    icon={<EditOutlined/>}
                    onClick={(e) => {
                        e.stopPropagation();

                        setAssetId(asset.id);
                        setModalAction("edit");
                    }}
                />
            )
        }
    ];

    const modalCloseHandler = useCallback(async () => {
        setModalAction(undefined);
        setAssetId(undefined);
        refetchFilters();
    }, []);

    return (
        <ProPageContainer>
            <ProTable<Asset>
                {...tableProps}
                loading={tableQueryResult.isFetching}
                rowKey="id"
                headerTitle
                search={false}
                columns={columns}
                rowClassName="asset-list-table-row"
                onRow={(record) => {
                    return {
                        onClick: () => show(resource.route!, record.id)
                    };
                }}
                toolbar={{
                    actions: [
                        <Button
                            icon={<PlusOutlined/>}
                            type="primary"
                            onClick={() => {
                                setModalAction("create");
                                setAssetId(undefined);
                            }}
                        >
                            Add New
                        </Button>
                    ],
                }}
                options={{
                    reload: () => {
                        tableQueryResult.refetch();
                        refetchFilters();
                    }
                }}
            />
            <AssetModal
                id={assetId}
                action={modalAction}
                onClose={modalCloseHandler}
                key={assetId}
            />
        </ProPageContainer>
    );
};
