import Fuse from "fuse.js";

export class BatchColumnMapper {
    private fuse;

    constructor(matchOptions: Array<{ variable: string, alias: string[] }>) {
        this.fuse = new Fuse(matchOptions, {
            keys: ["alias"],
            includeScore: true,
            shouldSort: true,
            threshold: 0.4,
        });
    }

    match(columns: string[]): Record<string, string> { // {<column>: <variable>}
        const bestMappings: Record<string, { score: number, column: string }> = {};
        columns.forEach((column) => {
            // we get first item because result is sorted
            const [res] = this.fuse.search(column);
            if (res != null) {
                const {item: {variable}} = res;
                const score = res.score!;

                if (bestMappings[variable] == null || bestMappings[variable]!.score > score) {
                    bestMappings[variable] = {
                        score,
                        column
                    }
                }
            }
        });

        return Object.entries(bestMappings)
            .reduce<Record<string, string>>((res, [variable, info]) => {
                res[info.column] = variable;
                return res;
            }, {});
    }
}
