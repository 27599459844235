import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

export const initSentry = () => {
    Sentry.init({
        dsn: "https://4824a74726674b58b8390d0c5541f7ae@o4504411166736384.ingest.sentry.io/4504411178598400",
        release: "tbt-gui@0.1.0",
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
    });
}
