import {DateTime} from "luxon";
import {BatchPeriodType} from "services/api/interface/Batch";

export function calculateBatchPeriod(periodType?: BatchPeriodType | null, periodYear?: number | null, period?: number | null) {
    let startDate: DateTime | null = null;
    let endDate: DateTime | null = null;

    if (periodType != null && periodYear != null) {
        switch (periodType) {
            case BatchPeriodType["Annual"]: {
                const annualDateTime = DateTime.local(periodYear);
                startDate = annualDateTime.startOf("year");
                endDate = annualDateTime.endOf("year");
                break;
            }
            case BatchPeriodType["Monthly"]: {
                if (period != null) {
                    const monthlyDateTime = DateTime.local(periodYear, period);
                    startDate = monthlyDateTime.startOf("month");
                    endDate = monthlyDateTime.endOf("month");
                }
                break;
            }
            case BatchPeriodType["Quarterly"]: {
                if (period != null) {
                    const monthlyDateTime = DateTime.local(periodYear, 3 * period - 1);
                    startDate = monthlyDateTime.startOf("quarter");
                    endDate = monthlyDateTime.endOf("quarter");
                }
                break;
            }
        }
    }

    if (startDate == null && endDate == null) {
        return null;
    }
    else {
        return {
            periodStart: startDate?.toISODate(),
            periodEnd: endDate?.toISODate(),
        };
    }
}
