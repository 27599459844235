import {useMemo} from "react";
import {
    Button,
    Col,
    Divider,
    Row,
    Space,
    Statistic as AntdStatistic,
    StatisticProps,
} from "@pankod/refine-antd";
import {useShow} from "@pankod/refine-core";
import {ProCard} from "@ant-design/pro-components";

import {ProPageContainer} from "components/layout/ProPageContainer";
import {BatchStatusTag} from "components/batch";
import {Descriptions} from "components/Descriptions";
import {LabelWithHint} from "components/LabelWithHint";
import {ViewChart} from "components/ViewChart";
import {SyncButton} from "components/syncButton";
import {
    Batch,
    BatchDataType,
    BatchPostAnalysis,
    BatchSourceType,
    BatchTarget,
    BatchesWithPeriod
} from "services/api/interface/Batch";
import {getEnumLabel} from "utils/getEnumLabel";
import {resolvePeriod} from "utils/batch/resolvePeriod";

const Statistic = ({valueStyle = {}, ...props}: StatisticProps) => {
    if (props.value == null) {
        return null;
    }
    else {
        return <AntdStatistic {...props} valueStyle={{...valueStyle, textAlign: "right"}}/>;
    }
}

export const BatchView = () => {
    const {queryResult} = useShow<Batch>();
    const {data, isLoading} = queryResult;
    const batch = data?.data;

    const showPeriod = useMemo(() => {
        return batch?.type != null && BatchesWithPeriod.includes(batch.type);
    }, [batch?.type]);

    const period = useMemo(() => resolvePeriod(batch), [batch]);

    return (
        <ProPageContainer loading={isLoading} title={batch?.name}>
            <ProCard>
                <Row
                    justify="center"
                    gutter={[8, 16]}
                    style={{padding: "0.5rem 0 1.25rem"}}
                >
                    <Col>
                        <Space>
                            <Button disabled>Analyze</Button>
                            <SyncButton batch={batch} onSync={queryResult.refetch}/>
                            <Button>Archive</Button>
                        </Space>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col style={{maxWidth: "56rem"}}>
                        <Descriptions
                            column={3}
                            items={[
                                {label: "Data Type", value: getEnumLabel(BatchDataType, batch?.type)},
                                {label: "Target", value: getEnumLabel(BatchTarget, batch?.target)},
                                {label: "Post Analysis", value: getEnumLabel(BatchPostAnalysis, batch?.post_analysis)},
                                {label: "Source Type", value: getEnumLabel(BatchSourceType, batch?.source)},
                                {label: "Filename", value: batch?.orig_file_name},
                                {label: "Sheet", value: batch?.selected_sheet},
                                {label: "Status", value: <BatchStatusTag status={batch?.status}/>},
                                {label: "Period", value: period, hidden: !showPeriod},
                            ]}
                        />
                    </Col>
                </Row>
                <Divider/>
                <Row justify="center">
                    <Col style={{maxWidth: "56rem", width: "100%", marginBottom: "1rem"}}>
                        <Row justify="space-between" style={{marginBottom: "1rem"}}>
                            <Statistic title="Total Rows" value={batch?.stats?.row_count}/>
                            <Statistic title="Total Spend" value={round(batch?.stats?.total_spend)} prefix="$"/>

                            {batch?.type === BatchDataType["General Ledger"] && (
                                <>
                                    <Statistic title="Total Accounts" value={batch?.stats?.account_count}/>
                                    <Statistic
                                        value={round(batch?.stats?.total_spend_included)}
                                        title={<LabelWithHint
                                            title="Spend Included"
                                            hint="Included based on relevancy to carbon accounting."
                                        />}
                                        prefix="$"
                                    />
                                    <Statistic
                                        value={round(batch?.stats?.total_spend_ignored)}
                                        title={<LabelWithHint
                                            title="Spend Excluded"
                                            hint="Excluded based on no relevancy to carbon accounting."
                                        />}
                                        prefix="$"
                                    />
                                </>
                            )}

                            {/* TODO: Pass values when props will be existing */}
                            {batch?.type === BatchDataType["Purchase Ledger"] && (
                                <Statistic title="Total Items" value={0}/>
                            )}

                            {batch?.type === BatchDataType["Assets"] && (
                                <>
                                    <Statistic
                                        title="Total Mobile Assets"
                                        value={batch?.stats?.total_mobile_assets}
                                    />
                                    <Statistic
                                        title="Total Stationary Assets"
                                        value={batch?.stats?.total_stationary_assets}
                                    />
                                    <Statistic
                                        title="Total Occupied Area"
                                        value={batch?.stats?.total_occupied_area}
                                        suffix={<>m<sup>2</sup></>}
                                    />
                                </>
                            )}

                            {batch?.type === BatchDataType["Fuel Usage"] && (
                                <>
                                    <Statistic
                                        title="Total Liquid Fuel Quantity"
                                        value={batch?.stats?.total_liquid_fuel_quantity}
                                    />
                                    <Statistic
                                        title="Total Gas Fuel Quantity"
                                        value={batch?.stats?.total_gas_fuel_quantity}
                                    />
                                </>
                            )}

                            {batch?.type === BatchDataType["Electricity Usage"] && (
                                <>
                                    <Statistic
                                        title="Total Electricity Usage"
                                        value={batch?.stats?.total_quantity}
                                    />
                                </>
                            )}
                        </Row>
                    </Col>
                </Row>
                <Row justify="center">
                    <Space>
                        <Button type="link">Log</Button>
                        <Button type="link">Success</Button>
                        <Button type="link">Error</Button>
                    </Space>
                </Row>

                {(batch?.type === BatchDataType["General Ledger"] || batch?.type === BatchDataType["Purchase Ledger"]) && (
                    <>
                        <Divider/>
                        <ViewChart batchId={batch?.id}/>
                    </>
                )}
            </ProCard>
        </ProPageContainer>
    );
};

function round(n?: number) {
    return n == null ? undefined : Math.round(n * 100) / 100;
}