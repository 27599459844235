import React, {useCallback, useMemo} from "react";
import {DateTime} from "luxon";
import {
    IResourceComponentsProps,
    useGetIdentity,
    useNavigation,
    useResource,
    useRouterContext
} from "@pankod/refine-core";
import {Button, CreateButton, Divider, Empty, getDefaultSortOrder, Space, useTable, Modal} from "@pankod/refine-antd";
import {ProColumns, ProTable} from "@ant-design/pro-components";
import {LockOutlined, PlusOutlined} from "@ant-design/icons";

import {Batch, BatchDataType, BatchStatus} from "services/api/interface/Batch";
import {BatchStatusTag} from "components/batch";
import {ProPageContainer} from "components/layout/ProPageContainer";
import {SyncButton} from "components/syncButton";
import {getEnumLabel} from "utils/getEnumLabel";
import {resolvePeriod} from "utils/batch/resolvePeriod";

export const BatchList: React.FC<IResourceComponentsProps> = () => {
    const {tableProps, tableQueryResult, sorter} = useTable<Batch>({
        initialSorter: [{
            field: "created_at",
            order: "desc"
        }],
        syncWithLocation: true
    });
    const {showUrl: generateShowUrl} = useNavigation();
    const {resource} = useResource();
    const {Link} = useRouterContext();

    const columns: ProColumns<Batch>[] = [
        {
            title: "Create On",
            dataIndex: "created_at",
            sorter: true,
            defaultSortOrder: getDefaultSortOrder("created_at", sorter),
            showSorterTooltip: false,
            render: (val, record) =>
                DateTime.fromISO(record.created_at.toString()).toFormat("d/M/yyyy h:mma")
        },
        {
            title: "Type",
            dataIndex: "type",
            renderText: (val) => {
                return getEnumLabel(BatchDataType, val)
            }
        },
        {
            title: "Period",
            dataIndex: "reporting_period_type",
            renderText: (_, record) => resolvePeriod(record)
        },
        {
            title: "Status",
            dataIndex: "status",
            sorter: true,
            defaultSortOrder: getDefaultSortOrder("status", sorter),
            showSorterTooltip: false,
            render: (_, record) => (
                <BatchStatusTag status={record.status}/>
            ),
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (_, record) => (
                <Space split={<Divider type="vertical"/>}>
                    <Link to={generateShowUrl(resource.route!, record.id)}>
                        <Button size="small" type="link">View</Button>
                    </Link>
                    {
                        record.status === BatchStatus["Upload Pending"]
                            ? <Button size="small" type="link">Analyze</Button>
                            : <SyncButton batch={record} onSync={tableQueryResult.refetch} type="link"/>
                    }
                    <Button size="small" type="link">Archive</Button>
                </Space>
            )
        },
    ];

    const emptyText = useMemo(() => {
        return tableProps.loading ? false : "No items to display.";
    }, [tableProps.loading]);

    const onLockedAddClick = useCallback(() => {
        Modal.warning({
            title: "License required",
            content: (
                <p>Subscribe to activate. Email to <a
                    href="mailto:support@thebigtoolbox.com.au"
                    rel="noreferrer"
                    target="_blank"
                >support@thebigtoolbox.com.au</a></p>
            )
        })
    }, []);

    const {data: user} = useGetIdentity();

    const addButton = (user?.license_active && user?.license_type === "base")
        ? (
            <CreateButton icon={<PlusOutlined/>} type="primary">
                Add New
            </CreateButton>
        )
        : (
            <Button
                icon={<LockOutlined/>}
                type="primary"
                onClick={onLockedAddClick}
            >
                Add New
            </Button>
        );

    return (
        <ProPageContainer>
            <ProTable<Batch>
                {...tableProps}
                loading={tableQueryResult.isFetching}
                rowKey="id"
                headerTitle
                search={false}
                toolbar={{actions: [addButton]}}
                options={{
                    reload: () => {
                        tableQueryResult.refetch();
                    }
                }}
                columns={columns}
                locale={{emptyText: <Empty description={emptyText}/>}}
            />
        </ProPageContainer>
    );
};
