import {useCallback, useEffect, useMemo, useState} from "react";
import {Col, Form, Input, Row, Select, Space, Statistic, Switch, Table, Tag, Typography} from "@pankod/refine-antd";
import _ from "lodash";

import {FormContainer} from "components/MultiStep/FormContainer";
import {ParseFileResult} from "services/workers/parseFile";
import {BatchColumnMapper} from "./helpers/BatchColumnMapper";
import {BatchColumnsType, BatchDataType} from "services/api/interface/Batch";

export type ColumnMappingTableProps = {
    parseResult?: ParseFileResult;
    labelSpan: number;
    batchColumns?: BatchColumnsType;
}

type ColumnTableRow = {
    column: string;
    data: object[];
    attribute?: string;
}

const {Text} = Typography;

export const ColumnMappingTable = ({parseResult, labelSpan, batchColumns}: ColumnMappingTableProps) => {
    const form = Form.useFormInstance();
    const [tableRows, setTableRows] = useState<ColumnTableRow[]>();
    const [onlyMapped, setOnlyMapped] = useState(false);

    const type: BatchDataType = Form.useWatch("type", form);

    const filteredTableRows = useMemo(() => {
        return onlyMapped ? tableRows?.filter((it) => it.attribute != null) : tableRows;
    }, [tableRows, onlyMapped]);

    const column_mappings: Record<string, string> = Form.useWatch("column_mappings", form) ?? [];
    const setMapping = useCallback((value?: Record<string, string>) => {
        form.setFieldValue("column_mappings", value);
    }, [form.setFieldValue]);

    const sheet = Form.useWatch("selected_sheet", form);
    const setSheet = useCallback((value?: string) => {
        form.setFieldValue("selected_sheet", value)
    }, [form]);

    const {columnsMeta, batchColumnMapper, columnOptions} = useMemo(() => {
        const batchTypeColumns = batchColumns?.[type];
        const columnsMeta = batchTypeColumns == null
            ? undefined
            : _.map(batchTypeColumns, (it, variable) => ({variable, ...it}));

        return {
            columnsMeta,
            batchColumnMapper: columnsMeta == null ? undefined : new BatchColumnMapper(columnsMeta),
            columnOptions: columnsMeta?.map(({variable, label}) => ({value: variable, label}))
        }
    }, [type, batchColumns]);


    const unmappedColumns = useMemo(() => {
        return columnsMeta?.filter((it) => column_mappings[it.variable] == null) ?? []
    }, [column_mappings, columnsMeta]);

    const sheetJson = useMemo<Array<Record<string, any>> | null>(() => {
        if (parseResult?.worksheets == null || sheet == null) {
            return null;
        }
        else {
            return parseResult?.worksheets[sheet]?.json;
        }
    }, [parseResult?.worksheets, sheet]);

    const sheetColumns = useMemo(() => {
        if (sheetJson == null || sheetJson.length === 0) {
            return undefined;
        }
        const [row] = sheetJson;
        return Object.keys(row!);
    }, [sheetJson]);

    const stats = useMemo(() => {
        if (sheetJson == null || sheetColumns == null) {
            return undefined;
        }
        else {
            return {
                rowCount: sheetJson.length,
                columnCount: sheetColumns.length,
                totalAccounts: undefined
            };
        }
    }, [sheetJson, sheetColumns]);

    const sheetOptions = useMemo(() => {
        if (parseResult == null || !parseResult.valid) {
            return null;
        }
        else {
            return parseResult!.SheetNames!.map((name) => ({
                value: name,
                label: name,
                disabled: !(parseResult.worksheets![name]!.valid)
            }));
        }
    }, [parseResult, parseResult?.valid, parseResult?.SheetNames, parseResult?.worksheets]);

    const setAttribute = useCallback((column: string, newAttribute: string) => {
        if (tableRows == null) {
            return;
        }

        const newTableRows = tableRows.map(({attribute, ...rest}) => {
            if (rest.column === column) {
                attribute = newAttribute
            }
            else if (attribute === newAttribute) {
                attribute = undefined;
            }

            return {
                ...rest,
                attribute,
            };
        });

        setTableRows(newTableRows);
    }, [tableRows]);

    useEffect(() => {
        if (sheetColumns == null || batchColumnMapper == null) {
            setTableRows(undefined);
        }
        else {
            // Search best mappings
            const columnAttributeMapping = batchColumnMapper.match(sheetColumns);

            setTableRows(sheetColumns.map((column) => ({
                column,
                data: sheetJson?.[0]?.[column],
                attribute: columnAttributeMapping[column]
            })));
        }
    }, [sheetJson, sheetColumns, batchColumnMapper]);

    // Set initial sheet selector value
    useEffect(() => {
        setSheet(parseResult?.SheetNames?.find((name) => parseResult?.worksheets?.[name]?.valid))
    }, [setSheet, parseResult?.SheetNames, parseResult?.worksheets]);

    useEffect(() => {
        if (tableRows == null) {
            return;
        }
        const newMapping: Record<string, string> = {};
        tableRows.forEach((row) => {
            if (row.attribute != null) {
                newMapping[row.attribute] = row.column;
            }
        });
        setMapping(newMapping);
    }, [tableRows, setMapping]);

    useEffect(() => {
        const defaultSheetOption = sheetOptions?.find((it) => !it.disabled);
        setSheet(defaultSheetOption?.value);
    }, [sheetOptions, setSheet]);

    return (
        <>
            {sheetOptions && (
                <FormContainer>
                    <Form.Item label="Select worksheet" name="selected_sheet" labelAlign="right">
                        <Select options={sheetOptions}/>
                    </Form.Item>
                    <Form.Item hidden name="file">
                        <Input/>
                    </Form.Item>
                    <Form.Item hidden name="column_mappings">
                        <Input/>
                    </Form.Item>
                </FormContainer>
            )}
            {stats && (
                <FormContainer>
                    <Form.Item wrapperCol={{offset: labelSpan}}>
                        <Row justify="center">
                            <Space size="large">
                                <Statistic title="Total Rows" value={stats.rowCount}/>
                                <Statistic title="Total Columns" value={stats.columnCount}/>
                            </Space>
                        </Row>
                    </Form.Item>
                </FormContainer>
            )}
            <FormContainer>
                <Row justify="center" style={{marginBottom: "1.5rem"}} wrap={false}>
                    <Text
                        type="secondary"
                        style={{marginRight: 12, whiteSpace: "nowrap"}}
                    >
                        Un-mapped Attributes
                    </Text>
                    <Space size={4} wrap>
                        {unmappedColumns.map((col) => (
                            <Tag color="processing" key={col.variable}>
                                {col.label}
                                {!col.optional && (
                                    <Text type="danger" style={{fontSize: "inherit"}}> *</Text>
                                )}
                            </Tag>
                        ))}
                        {unmappedColumns.length === 0 && (
                            <Text style={{color: "rgba(24, 144, 255, 0.45)"}}>None</Text>
                        )}
                    </Space>
                </Row>
            </FormContainer>
            {sheetColumns && (
                <Form.Item>
                    <Table
                        pagination={false}
                        rowKey="column"
                        size="middle"
                        scroll={sheetColumns.length > 5 ? {y: 284} : undefined}
                        columns={[
                            {title: "Column", dataIndex: "column"},
                            {title: "Data", dataIndex: "data"},
                            {
                                dataIndex: "attribute",
                                width: "30%",
                                render: (value, record) => (
                                    <Col span={24}>
                                        <Select style={{width: "100%"}}
                                                options={columnOptions}
                                                value={value}
                                                onChange={(v) => {
                                                    setAttribute(record.column, v)
                                                }}
                                        />
                                    </Col>
                                ),
                                title: () => (
                                    <Row justify={"space-between"}>
                                        Attribute
                                        <Switch
                                            checkedChildren="MAPPED"
                                            unCheckedChildren="ALL"
                                            checked={onlyMapped}
                                            onChange={setOnlyMapped}
                                        />
                                    </Row>
                                ),
                            },
                        ]}
                        dataSource={filteredTableRows}
                    />
                </Form.Item>
            )}
        </>
    );
}
