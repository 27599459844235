import {DraggerProps} from "antd/lib/upload/Dragger"
import {UploadFile, Upload} from "@pankod/refine-antd";
import {useCallback} from "react";
import {UploadChangeParam} from "antd/es/upload/interface";

export type ControlledDraggerProps = DraggerProps & {
    value?: UploadFile;
    onChange?: (file: UploadFile | undefined) => {}
}

export const Dragger = ({value, onChange, ...rest}: ControlledDraggerProps) => {
    const onDraggerChange = useCallback(({fileList: [file]}: UploadChangeParam<UploadFile>) => {
        onChange?.(file)
    }, [onChange]);

    return <Upload.Dragger {...rest} onChange={onDraggerChange} defaultFileList={value != null ? [value] : []}/>
}
