import {ChangeEventHandler, Key, ReactNode, useCallback, useMemo, useState} from "react";
import {Button, Checkbox, FilterDropdownProps, Input, Menu} from "@pankod/refine-antd";
import {SearchOutlined} from "@ant-design/icons";
import VirtualList from "rc-virtual-list";
import {searchValueMatched} from "utils/searchValueMatched";
import {getTextWidth} from "./getTextWidth";

type FilterItemProps = {
    itemKey: string,
    text: ReactNode,
    checked: boolean
}

const MIN_DROPDOWN_WIDTH = 200;
const ITEM_HEIGHT = 32;

const FilterItem = ({itemKey, text, checked}: FilterItemProps) => {
    return (
        <div>
            <Menu.Item eventKey={itemKey} key={itemKey} style={{width: "100%"}}>
                <Checkbox checked={checked}/>
                <span>{text}</span>
            </Menu.Item>
        </div>
    );
};

function VirtualFilters({filters}: { filters: FilterItemProps[] }) {
    return <VirtualList
        data={filters}
        height={filters.length < 8 ? filters.length * ITEM_HEIGHT : 256}
        itemHeight={ITEM_HEIGHT}
        itemKey="itemKey"
    >
        {FilterItem}
    </VirtualList>
}

export function FilterDropdown(props: FilterDropdownProps) {
    const {
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filters,
    } = props;

    const [search, setSearch] = useState("");
    const [dropdownInstance, setDropdownInstance] = useState<HTMLDivElement | null>();

    const width = useMemo(() => {
        let result = MIN_DROPDOWN_WIDTH;

        if (dropdownInstance != null && filters != null) {
            let longestText = "";

            filters?.forEach((filter) => {
                if (filter.text && filter.text.toString().length > longestText.length) {
                    longestText = filter.text.toString();
                }
            });

            // text width + menu padding + menu item padding + checkbox width
            result = Math.max(result, getTextWidth(longestText, dropdownInstance, MIN_DROPDOWN_WIDTH) + 8 + 24 + 16);
        }
        return result;
    }, [filters, dropdownInstance]);

    const normalizedFilters = useMemo<FilterItemProps[]>(() => {
        const res: FilterItemProps[] = [];
        filters?.forEach((filter) => {
            if (searchValueMatched(search, filter.text)) {
                res.push({
                    itemKey: String(filter.value),
                    text: filter.text,
                    checked: selectedKeys.includes(String(filter.value)),
                });
            }
        });

        return res;
    }, [filters, search, selectedKeys]);

    const onSearch = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
        setSearch(e.target.value);
    }, []);

    const onSelectKeys = useCallback(({selectedKeys}: { selectedKeys: Key[] }) => {
        setSelectedKeys(selectedKeys);
    }, [setSelectedKeys]);

    return <div ref={(instance) => setDropdownInstance(instance)} style={{width: width}}>
        {filters && filters.length >= 9 && (
            <div
                style={{
                    padding: 8,
                    borderBottom: "solid rgba(5, 5, 5, 0.06) 1px"
                }}
            >
                <Input
                    prefix={<SearchOutlined style={{color: "rgba(0, 0, 0, 0.25)"}}/>}
                    placeholder="Search in filters"
                    value={search}
                    onChange={onSearch}
                />
            </div>
        )}

        <Menu
            selectable
            multiple
            onSelect={onSelectKeys}
            onDeselect={onSelectKeys}
            selectedKeys={selectedKeys as string[]}
        >
            <VirtualFilters filters={normalizedFilters}/>
        </Menu>

        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                padding: 8,
                borderTop: "solid rgba(5, 5, 5, 0.06) 1px"
            }}
        >
            <Button type="link" size="small" onClick={() => clearFilters?.()}>
                Reset
            </Button>
            <Button type="primary" size="small" onClick={() => confirm()}>
                OK
            </Button>
        </div>
    </div>
}
